[role='dialog'][aria-describedby='email-subscription-popup'] {
  min-width: 60rem;
  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.051);
  z-index: 905;

  @include media-breakpoint-down(sm) {
    min-width: 90%;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }

  .ui-dialog-title {
    display: none;
  }

  .ui-dialog-titlebar-close {
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: transparent;
    outline: 0;
    background-image: url('../images/icon-close.svg');
    z-index: 1;

    img {
      width: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .set-vehicle-form-container {
    padding: 0;

    .tab.content::before {
      content: unset;
    }

    fieldset {
      flex-direction: row;
      gap: 0;

      .field.registration-number {
        padding-right: 0.75rem;
      }

      .field.state {
        padding-left: 0.75rem;
      }

      .field {
        flex-basis: 50%;
      }

      @include media-breakpoint-down(sm) {
        align-items: flex-end;

        .field.registration-number {
          padding-right: 0.75rem;
        }

        .field.state {
          padding-left: 0.75rem;
        }
      }
    }

    [id*='_make_model_form_content'] {

      fieldset {
        flex-direction: column;
        align-items: normal;
        gap: 3rem;
      }
    }

    .actions-toolbar {
      margin-top: 3rem;
    }
  }

  .promotion-image-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% * 9 / 16);
    position: relative;
    overflow: hidden;

    [data-content-type='row'] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;

      .popup-header {
        justify-content: center !important;
        background-position: center !important;
        align-items: center;
        height: 100%;

        .header-title {
          color: $white;
          font-size: 4rem;
          font-weight: 700;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);

          @include media-breakpoint-down(sm) {
            font-size: 2.5rem;
          }
        }
      }
    }

    [data-content-type='image']:not(:empty) {
      display: flex;
      justify-content: center;
      height: 100%;
      overflow: hidden;

      img {
        max-width: unset !important;
        width: auto;
        max-height: 100%;
      }
    }
  }

  .email-subscription-popup-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 3rem;

    .customer-name {
      display: flex;

      > .field {
        flex: 0 0 50%;
      }
    }

    .field {

      &.required {

        .label::after {
          content: ' (Required)';
        }
      }

      .label {
        margin-bottom: 1rem;
      }
    }

    .actions-toolbar {
      display: flex;
      justify-content: center;
    }

    .setvehicle-result-booking {
      margin-top: 0;

      .message-success {
        margin-top: 0;
      }
    }

    .btn-set-vehicle {
      display: none;
    }
  }

  .ui-dialog-buttonset {
    padding: 1rem 2.5rem 5rem;

    button {
      width: 100%;
      font-size: 1.8rem;
    }
  }

  .form-check-label[for='popup_consent'] {
    max-width: 46rem;
    margin-left: 2rem;

    @include media-breakpoint-down(sm) {
      max-width: 80%;
    }
  }
}

.email-modals-overlay {
  background-color: rgba(0, 0, 0, 0.49);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
