[data-content-type='faq'] {
  border-bottom: none;
  padding-left: 0;

  [data-content-type='faq-item'] {
    margin-bottom: 1.1rem !important;

    [data-element='question'] {
      padding: 3rem 2.5rem !important;
      font-size: 2rem;
      position: relative;
      border-bottom: solid 2px $border-color;

      &::after {
        position: absolute;
        content: ' ';
        width: 32px;
        height: 32px;
        right: 3rem;
        top: 50%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='m15.594 21.06-8.707-8.707 1.414-1.414 7.293 7.293 7.293-7.293 1.414 1.414z'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        transform-origin: top;

        @include media-breakpoint-down(md) {
          width: 24px;
          height: 24px;
        }
      }

      &[aria-expanded='true']::after {
        transform: rotate(180deg) translateY(-50%);
      }

      &:hover {
        background-color: $light;
      }
    }

    [role='tabpanel'] {
      border-bottom: solid 2px $border-color;
    }

    [data-element='answer'] {
      padding: 2.5rem !important;
    }
  }
}
