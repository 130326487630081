.marquee-animation {
  overflow: hidden;

  [data-content-type='html'] > div {
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    &.enable-animation {
      overflow: visible;
    }
  }

  a {
    color: $dark-text;
    text-decoration: none;
    padding: 1rem 5rem;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  &.announcement-container {
    background: $gray-50;
    z-index: 120;
    font-weight: 700;

    [data-content-type='row'][data-appearance='contained'] {
      max-width: 100%;
    }

    ~ .block.block-search {
      top: 85px;
    }
  }

  .enable-animation {
    display: flex;
    will-change: transform;
    animation: marquee 30s linear infinite;
    width: fit-content;

    @include media-breakpoint-down(md) {
      animation: marquee 60s linear infinite;
    }

    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% + 100vw)); }
}
