@mixin form-element-status {
  border: 0;
  outline: 1px solid $input-border-color;

  @include transition($input-transition);

  &:focus,
  &:hover {
    outline: 2px solid $input-focus-border-color;
  }

  &:disabled {
    pointer-events: none;
  }

  &.mage-error {
    outline: 2px solid $input-error-border-color;
    background: $input-error-background;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}

select {
  height: 6rem;
  appearance: none;
  background: url('../images/icon-arrow-down.svg') center right 1.5rem no-repeat;
  background-size: 2.4rem 2.4rem;
  padding: 1rem 4rem 1rem 2.5rem;
  border-radius: 0.4rem;

  &:not(:disabled) {
    color: inherit;
  }

  &:invalid {
    color: $input-placeholder-color;
  }

  @include form-element-status;
}

input {
  height: $input-height;
  padding: 1rem 2.5rem;
  border-radius: 4px;
  width: 100%;

  @include form-element-status;
}

input[type='radio'] {
  padding: 0;
  margin: 0;
  background: $white;
  border: 2px solid $input-border-color;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  position: relative;
  appearance: none;

  &:checked {
    border-color: $black;

    &::after {
      content: '';
      position: relative;
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.5rem;
      background: $black;
    }
  }
}

input[type='checkbox'] {
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
}

.form-check {
  padding-left: 2.5rem;
  display: flex;
  align-items: center;

  .form-check-input {
    flex-shrink: 0;
    margin-left: -2.5rem;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}

textarea {
  padding: 2.2rem 2.5rem;
  min-height: 16.5rem;

  @include form-element-status;
}

.field-error,
div.mage-error {
  font-size: 1.2rem;
  color: $primary;
}

.terms-conditions-note {
  font-size: 1.4rem;
  margin: 1rem auto 5rem;
  width: 100%;
  max-width: 500px;

  @include media-breakpoint-down(md) {
    padding-bottom: 5rem;
  }
}

.express-consent-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.form-floating {

  > label {
    line-height: $form-floating-line-height;
  }

  > .form-control {
    border-radius: 0.5rem;
  }
}
