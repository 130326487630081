.prefooter {
  background-color: $black;
}

.grecaptcha-badge {
  display: none !important;
}

.page-footer {
  background: $white;
}

.footer.content {
  border-top: none;
  padding: 0;

  @include media-breakpoint-down(md) {
    margin-top: 1.6rem;
  }
}

.newsletter-container {
  background-color: $primary;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 3rem;
    margin-bottom: 0;
  }

  .newsletter-inner {
    position: relative;
  }

  .block.newsletter {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: none;
    margin-bottom: 0;

    #newsletter-error {
      color: $white;
      position: absolute;
      right: 0;
      top: 100%;
      width: 400px;
      text-align: right;
      margin-top: 0.5rem;
    }

    .field {
      width: 50%;
      display: block;
      position: relative;

      @media (max-width: 500px) {
        width: 100%;

        &.name,
        &.newsletter {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .control {
        position: relative;
      }

      input {
        margin-right: 0;
        outline: none;
        border: 2px solid transparent;
        font-size: 1.4rem;

        @include media-breakpoint-up(md) {

          &:focus {
            border-color: $black;
          }
        }
      }

      label {
        width: 100%;
      }

      &.name {
        margin-right: 0;

        @include media-breakpoint-up(md) {
          padding-right: 0.3rem;
        }
      }

      &.newsletter {
        max-width: none;
        margin-right: 0;

        @include media-breakpoint-up(md) {
          padding-left: 0.3rem;
        }
      }
    }

    .actions {
      position: absolute;
      right: 12px;
      width: 32px;
      height: 32px;
      display: block;
      top: 50%;
      transform: translateY(-50%);

      .subscribe {
        background: none;
        border: none;
        outline: none;
        background-image: url('../images/icon-arrow-right2.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;

        span {
          display: none;
        }
      }
    }

    .control::before {
      display: none;
    }

    input {
      padding: $input-padding-y $input-padding-x;
      width: 100%;
      height: 58px;
    }
  }

  .newsletter-text-holder {
    width: 100%;

    @include media-breakpoint-down(lg) {
      margin-top: 95px;
      margin-bottom: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 270px;
      padding-right: 20px;
      width: 57%;
    }

    img {
      position: absolute;
      z-index: 1;

      @include media-breakpoint-down(lg) {
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: 170px !important;
      }

      @include media-breakpoint-up(lg) {
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    p {
      color: $white;
      text-transform: uppercase;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 2.7rem;

      @include media-breakpoint-up(lg) {
        text-align: right;
        margin-bottom: 0;
        margin-top: 4px;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
        line-height: 2.9rem;
        padding-left: 8%;
        padding-right: 8%;
      }
    }
  }

  .form.subscribe.form.subscribe {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      width: 43%;
    }
  }
}

.container-footer-links {

  p {

    @include media-breakpoint-down(md) {
      padding: 0 0.7rem;
    }
  }

  .btn-link {
    text-decoration: none;
    background-color: $white !important;
    color: $body-color !important;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    &:active {
      box-shadow: none;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 1.4rem;
      padding: 0.7rem 1.5rem 0.5rem 1.5rem;
      border-bottom: solid 1px $border-color !important;
      border-radius: 0;
      margin-bottom: 0;
    }

    a {
      font-size: 1.8rem;

      @include media-breakpoint-down(md) {
        pointer-events: none;
        font-size: 1.4rem;
        padding: 1rem;
      }
    }

    img {

      @include media-breakpoint-up(md) {
        display: none;
      }

      @include media-breakpoint-down(md) {
        position: absolute;
        top: 50%;
        right: 2.5rem;
        transform: translateY(-50%);
      }
    }
  }

  &.container-footer-links.container-footer-links li {
    margin-bottom: 1rem;
  }

  a {
    font-size: 1.4rem;
    color: $body-color;
    text-decoration: none;
  }

  .footer-list {

    @include media-breakpoint-down(md) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @include media-breakpoint-up(md) {

    .collapse,
    .collapsing {
      display: block !important;
      height: auto !important;
    }
  }

  .footer-accord-holder {

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }

    @include media-breakpoint-down(md) {

      .collapse ul,
      .collapsing ul {
        margin-top: 1.8rem;
        margin-left: 2rem;
      }
    }
  }

  .follow-us-title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .follow-us.follow-us.follow-us {

    ul {

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 2.5rem;
      }
    }

    li {

      @include media-breakpoint-down(md) {
        display: inline-block;
        margin: 0.9rem;

        a {
          font-size: 0;
        }
      }

      svg,
      img {

        @include media-breakpoint-up(md) {
          margin-right: 1.8rem;
        }

        @include media-breakpoint-down(md) {
          width: 3.1rem;
          height: 3.1rem;
        }
      }
    }
  }
}

.footer-copyright {
  background-color: $black;
  color: $white;
  margin-top: 2.5rem;

  a {
    color: $white;
  }

  .footer-inner {
    margin: 0 auto;
    padding: 0.8rem 1rem;
    max-width: map-get($container-max-widths, 'xl');
    display: flex;
    flex-wrap: no-wrap;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      text-align: center;
      padding: 2.5rem 1rem 0 1rem;
    }

    .copyright-text {

      @include media-breakpoint-up(md) {
        display: flex;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 1.9rem;
      }

      p {
        margin: auto 0;
        font-size: 1.3rem;
        font-weight: 700;
      }
    }

    .copyright-links {

      @include media-breakpoint-up(md) {
        display: flex;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      ul {
        padding: 0 0 0 2.5rem;
        margin: auto 0;
        list-style: none;
        display: flex;

        @include media-breakpoint-down(md) {
          justify-content: center;
          padding-left: 0;
        }

        li {
          padding: 0 2.5rem;
          margin: 0;
          border-left: solid 1px $white;
          line-height: 1.5rem;

          a {
            font-size: 1.3rem;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          @include media-breakpoint-down(md) {

            &:first-child {
              border-left: none;
            }
          }

          @media (max-width: 415px) {
            padding: 0 1.5rem;
          }
        }
      }
    }

    .copyright-trade {
      margin-left: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
        order: -1;
        margin-bottom: 1.9rem;
      }

      .btn {
        padding: 1.2rem 2.8rem;

        span {
          display: inline-block;
          text-indent: -9999px;
        }
      }
    }
  }

  .acknowledgement {
    margin: 0 auto;
    padding: 0 1rem 1rem 1rem;
    max-width: map-get($container-max-widths, 'xl');

    @include media-breakpoint-down(md) {
      padding: 2.5rem 4rem 2.9rem 4rem;
    }

    p {
      margin: auto 0;
      font-size: 1.3rem;
    }
  }
}
