.book-service-container {
  background: rgba(143, 143, 143, 0.102);
  padding: 3rem 2.8rem 1.8rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding-top: 2.5rem;
  }

  h2 {
    margin-top: 4rem;

    @include media-breakpoint-up(md) {
      margin-top: 6rem;
    }
  }

  h2,
  ~ .parts-catalogue-container h2 {
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 3.5rem;
    }
  }

  [data-content-type='button-item'] [data-element='link'],
  [data-content-type='button-item'] [data-element='empty_link'] {
    min-width: 163px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagebuilder-column-group {
    max-width: 346px;
    margin: auto;
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      max-width: 1090px;
    }
  }

  h3 {
    font-size: 2.5rem;
    margin: 4.6rem 0;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
      margin: 5.6rem 0;
    }
  }

  .book-service {

    .pagebuilder-column-line {
      gap: 4.4rem;

      @include media-breakpoint-up(md) {
        gap: 2.6rem;
      }
    }

    .pagebuilder-column {
      background: $white;
      border-radius: 0.4rem;
      overflow: hidden;
      gap: 3rem;
    }

    h3,
    h5 {
      font-size: 2rem;
      line-height: 2.8rem;
      max-width: 210px;
      margin: 0 auto auto;
    }

    [data-content-type='text'] {
      font-size: 1.6rem;
      max-width: 250px;
      margin: 0 auto;

      p {
        margin-bottom: 0;
        line-height: 2.8rem;
      }

      a {

        &[title='Learn More'] {
          font-size: 1.4rem;
          line-height: 2.4rem;
          color: $black;
          font-weight: 600;
          display: flex;
          align-items: center;
          padding-left: 2rem;
          padding-right: 5.5rem;
          position: relative;

          &::after {
            content: '';
            background: url('../images/icon-arrow-right.svg') no-repeat;
            background-position: contain;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 2.5rem;
            transition: all 0.2s ease-in-out;
          }

          &:hover {
            color: $primary;

            &::after {
              background: url('../images/icon-arrow-right-primary.svg') no-repeat;
              background-position: contain;
              right: 0;
            }
          }
        }
      }
    }

    .pagebuilder-button {

      &-primary,
      &-secondary {
        margin-bottom: 4.2rem;
      }
    }

    .book-service-image-wrapper {
      position: relative;
      height: 0;
      padding-bottom: 58%;
      margin-bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      @include media-breakpoint-down(md) {
        background-position: 50% 30%;
        padding-bottom: 32%;
      }

      &.brakes-steering {
        background-image: url('../images/homepage/Brakes-Steering-suspension-check.jpg');
      }

      &.wheel-alignment {
        background-image: url('../images/homepage/Wheel-Alignment.jpg');
      }

      &.tow-and-load {
        background-image: url('../images/homepage/Tow-Load-Assessment-1.jpg');
      }

      .book-service-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .view-all {

    .pagebuilder-button-secondary {
      background-color: transparent;
      color: $secondary;
      margin: 6rem auto;

      &:hover {
        background: $secondary;
        color: $white;
        border-color: $secondary;
      }
    }
  }
}
