// test
@mixin btn() {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};

  @include rfs($btn-font-size, --#{$prefix}btn-font-size);

  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-bg: #{$secondary};
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: #{$secondary};
  // scss-docs-end btn-css-vars

  display: inline-block;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  border-radius: #{$btn-border-radius} !important;

  @include font-size(var(--#{$prefix}btn-font-size));

  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);

  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include transition($btn-transition);

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }
}

.btn-primary,
.pagebuilder-button-primary {

  @include btn();
  @include button-variant(
    $primary,
    $primary,
    $color: $white,
    $active-border: $primary,
    $active-background: $primary,
    $active-color: $white,
    $hover-background: $secondary,
    $hover-border: $secondary,
    $hover-color: $white
  );
}

.btn-secondary,
.pagebuilder-button-secondary {

  @include btn();
  @include button-variant(
    $secondary,
    $secondary,
    $color: $white,
    $active-border: $secondary,
    $active-background: $secondary,
    $active-color: $white,
    $hover-background: $primary,
    $hover-border: $primary,
    $hover-color: $white
  );
}

.btn-outline-primary {

  @include btn();
  @include button-variant(
    transparent,
    $primary,
    $color: $primary,
    $active-border: $primary,
    $active-background: transparent,
    $active-color: $primary,
    $hover-background: $primary,
    $hover-border: $primary,
    $hover-color: $white
  );
}

.btn-outline-secondary {

  @include btn();
  @include button-variant(
    transparent,
    $secondary,
    $color: $secondary,
    $active-border: $secondary,
    $active-background: transparent,
    $active-color: $secondary,
    $hover-background: $secondary,
    $hover-border: $secondary,
    $hover-color: $white
  );
}

.btn-sm {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  font-size: $btn-font-size-sm;
  border-radius: $btn-border-radius-sm;
}

.btn-lg {
  padding: $btn-padding-y-lg $btn-padding-x-lg;
  font-size: $btn-font-size-lg;
  border-radius: $btn-border-radius-lg;
}

.action-close {

  &::before {
    content: '';
  }
}
