.rounded-xs {
  border-radius: $border-radius-xs;
}

.rounded-top-xs {
  border-top-left-radius: $border-radius-xs;
  border-top-right-radius: $border-radius-xs;
}

.rounded-bottom-xs {
  border-bottom-left-radius: $border-radius-xs;
  border-bottom-right-radius: $border-radius-xs;
}
