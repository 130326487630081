@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/ProximaNova-Regular.woff') format('woff'),
    url('../fonts/ProximaNova-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/proximanova-light-webfont.woff') format('woff'),
    url('../fonts/proximanova-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/proximanova-bold-webfont.woff') format('woff'),
    url('../fonts/proximanova-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/ProximaNova-Semibold.woff') format('woff'),
    url('../fonts/ProximaNova-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/proximanova-extrabld-webfont.woff') format('woff'),
    url('../fonts/proximanova-extrabld-webfont.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    url('../fonts/proximanova-regularit-webfont.woff') format('woff'),
    url('../fonts/proximanova-regularit-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
