body[class*='cms-booking'] {

  .page-title-wrapper {
    max-width: 596px;
    margin: 2.2rem auto 2rem;
    padding: 0 2.8rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 4rem;
      max-width: 360px;
    }
  }

  .booking-description {
    max-width: 500px;
    margin: auto;
    padding: 0 2.8rem;
  }
}

.cms-general-enquiry {

  .page-title-wrapper {
    padding: 0 6rem;
  }

  .booking-form-container {

    .change-store {
      display: block;
    }

    .section.help-info .field.addition-info {
      display: block;
    }
  }
}
