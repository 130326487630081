.offcanvas-header {
  padding: 0;

  .btn-close {
    position: absolute;
    top: 2.4rem;
    left: 100%;
    margin-left: 0.5rem;
    padding: 0;
  }
}

.offcanvas,
.offcanvas-lg {

  @include media-breakpoint-down(lg) {
    max-width: calc(100% - 59px);
  }
}
