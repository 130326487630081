@import '../components/home/slider';

.recent-posts-wrapper {

  .amblog-widget-container {
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .h5 {
    position: relative;
    display: inline-block;
    margin: 3rem 0 1.9rem;

    @include media-breakpoint-down(md) {
      margin: 2.4rem 0 2.3rem;
      padding-left: 2.8rem;
      font-size: 1.8rem;
      line-height: 1;
    }

    &::after {
      content: ' ';
      display: inline-block;
      background: url(../images/icon-right.svg) center center no-repeat;
      position: absolute;
      pointer-events: none;
      width: 24px;
      height: 24px;
      z-index: 1;
      right: -44px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    a {
      color: $body-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .amblog-list {
    padding: 0;

    .amblog-post {
      visibility: hidden;
      transition: $transition-fade;
      opacity: 0;

      &.slick-initialized {
        visibility: visible;
        opacity: 1;
      }

      &.slick-slider {

        .slick-dots {
          position: static;
          padding: 2.3rem 0;
          max-width: 1385px;
        }

        .slick-prev {
          left: 12px;

          @include media-breakpoint-up(sm) {
            left: -22px;
          }

          @media (min-width: 1500px) {
            left: -50px;
          }
        }

        .slick-next {
          right: 8px;
        }

        .slick-next,
        .slick-prev {
          transform: translateY(-6.8rem);

          @include media-breakpoint-down(sm) {
            transform: translateY(-8.8rem);
          }
        }

        .slick-list {
          padding-right: 8.9rem;

          .slick-slide {
            margin: 0 1.4rem;

            @include media-breakpoint-up(xxl) {
              width: 324px;
            }

            @include media-breakpoint-down(md) {
              width: 300px;
              margin: 0 1rem;
            }

            &:not(.slick-active) {
              opacity: 0.2;
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      .post-item-wrapper {
        height: 400px;
        margin: 0.4rem;

        @include media-breakpoint-down(md) {
          margin: 0.4rem 0;
        }

        .post-item-info {
          display: flex;
          flex-direction: column;
          height: 100%;
          border-radius: 4px;
          outline: solid 1px $gray-100;
          transition: outline 0.18s linear;

          @include media-breakpoint-up(md) {
            max-width: 324px;
          }

          &:hover {
            outline-color: $primary;
            outline-width: 4px;

            .post-item-details .learn-more a {
              color: $primary;

              &::after {
                background: url('../images/icon-arrow-right-primary.svg') no-repeat;
                right: 0;
              }
            }
          }

          .post-image {
            margin: 0;

            img {
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
          }

          .post-item-details {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 2.5rem 2.4rem 2.7rem 1.9rem;

            .post-category-badge {
              width: max-content;
              text-transform: uppercase;
              color: $white;
              background-color: $primary;
              padding: 0.7rem 1rem;
              font-size: 1.2rem;
              font-weight: 700;
              border-radius: 4px;
              line-height: 1;
              margin-bottom: 2rem;
            }

            .short-description {
              display: none;
            }

            .amblog-headline {
              text-decoration: none;
              font-size: 2rem;
              line-height: 1.45;
              font-weight: 400;
              flex: 1;

              &:hover {
                color: inherit;
              }
            }

            .learn-more {

              a {
                display: inline-flex;
                align-items: center;
                position: relative;
                font-size: 1.4rem;
                line-height: 1;
                color: $black;
                font-weight: 700;
                padding-right: 5.5rem;

                &::after {
                  content: '';
                  background: url('../images/icon-arrow-right.svg') no-repeat;
                  background-size: contain;
                  position: absolute;
                  width: 15px;
                  height: 15px;
                  right: 2.5rem;
                  transition: all 0.2s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}

.amblog-view-all {
  text-align: center;
  margin: 2.7rem 0 5rem;

  @include media-breakpoint-up(md) {
    margin: 2.7rem 0 12.7rem;
  }
}
