.block.block-search {
  margin: 1.5rem 1.7rem;
  z-index: 120;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 41px;
    z-index: 900;
    left: 48%;
    transform: translateX(-50%);
    width: calc(100% - 535px);
    max-width: 700px;
    padding: 0;
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    left: 50%;
    width: calc(100% - 620px);
  }
}

.cms-index-index,
.page-layout-category-landing,
.catalogsearch-result-index,
.cms-noroute-index {

  @include media-breakpoint-down(md) {

    main {
      margin-top: -9rem; //offset block-search height
    }

    .block-search .minisearch .control input {
      box-shadow: 0 0 3px rgba($black, 0.161);
    }
  }
}

.catalog-category-view .page-wrapper {

  @include media-breakpoint-down(md) {

    > .category-view {
      margin-top: -9rem;
    }

    .block-search .minisearch .control input {
      box-shadow: 0 0 3px rgba($black, 0.161);
    }
  }
}

.block-search .minisearch {

  @include media-breakpoint-down(md) {
    position: relative;
  }

  .label {

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .control {
    margin: 0;
    padding: 0;
    border: 0;

    @include media-breakpoint-down(md) {
      top: 0;
      background: transparent;
      position: relative;
    }

    input {
      font-size: 1.4rem;
      left: 0;
      color: $input-color;
      box-shadow: 0 0 0 0 $primary;
      transition: all 0.3s ease-out;
      outline: none;
      margin: 0;
      background-clip: border-box;

      @include media-breakpoint-down(lg) {
        font-size: 1.6rem;
      }

      ::placeholder,
      ::-ms-input-placeholder {
        color: $border-color;
      }

      @include media-breakpoint-down(md) {
        position: relative;
        left: auto;
        width: 100%;
        box-shadow: 0 0 6px rgba($black, 0.161);
      }

      &.form-control:focus {
        border-color: $primary;
        box-shadow: 0 0 0 3px $primary;
        outline: none;
        color: $input-color;
      }
    }
  }

  input[type=reset] {
    position: absolute;
    left: auto;
    right: 7rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 1.7rem;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 2rem;
    display: block;
    line-height: 1;
    border: none;
    outline: none;
    background: $gray-50 url(../images/icon-close-black.svg) no-repeat;
    z-index: 20;

    &:hover {
      border: none;
      outline: none;
    }
  }

  .action.search {
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 3%;
    z-index: 20;
    position: absolute;
    display: block;
  }

  .nested.nested {
    display: none;
  }
}
