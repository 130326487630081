.find-a-store {
  margin: 0 auto;
  padding: 7.5rem 2.8rem 3rem;
  max-width: 1433px;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 5.1rem 2.8rem 3rem;
  }

  &-title {
    margin-bottom: 2.3rem;

    p {
      position: relative;
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1;
      margin: 0 !important;

      &::after {
        content: ' ';
        display: inline-block;
        background: url('../images/icon-right.svg') center center no-repeat;
        position: absolute;
        pointer-events: none;
        width: 24px;
        height: 24px;
        z-index: 1;
        right: -44px;
        top: 50%;
        transform: translateY(-50%);
      }

      a {
        color: $body-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .pagebuilder-banner-wrapper {
    height: 347px;
    border-radius: 4px;

    @include media-breakpoint-down(md) {
      height: 320px;
    }

    .pagebuilder-overlay {
      height: 100%;
      padding: 3.8rem 0;

      @include media-breakpoint-down(md) {
        padding: 4.8rem 0;
      }

      [data-element='content'] {
        overflow: hidden;
        min-height: unset;
      }

      .pagebuilder-poster-content {
        text-align: center;
        height: 100%;

        p {
          color: $white;
          font-size: $h3-font-size;
          margin-bottom: 0;
          line-height: 1;
          font-weight: 700;
          padding: 0 5.8rem;

          @include media-breakpoint-down(md) {
            font-size: 2rem;
            line-height: 2.5rem;
            padding: 0 5.4rem;
          }

          img {
            margin-bottom: 2.7rem;

            @include media-breakpoint-down(md) {
              margin-bottom: 1.8rem;
            }
          }
        }

        .pagebuilder-button-secondary {
          border-color: $white;
          margin-top: 4.3rem;
          width: 21.3rem;

          @include media-breakpoint-down(md) {
            margin-top: 1.8rem;
          }
        }
      }
    }
  }
}
