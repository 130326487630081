.page-layout-category-landing {

  .page-main,
  .column.main {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .page-main + .page-footer {

    @include media-breakpoint-down(md) {
      margin-top: 5rem;
    }
  }

  .sidebar {
    display: none;
  }

  .category {

    &-banner {

      .banner-content {
        max-width: 1570px;
        margin: 0 auto;
      }

      .pagebuilder-banner-wrapper {

        @include media-breakpoint-down(md) {
          background-size: contain !important;
        }

        .pagebuilder-overlay {
          min-height: 0 !important;
          height: 140px;
          padding: 2.2rem 0;

          @include media-breakpoint-up(md) {
            height: 260px;
          }

          .pagebuilder-poster-content {

            h2,
            p {
              font-size: 1.8rem;
              line-height: 2.5rem;
              margin: 0;

              @include media-breakpoint-up(md) {
                font-size: 3.2rem;
                line-height: 4.2rem;
              }
            }

            .pagebuilder-banner-button {
              margin-top: 1.4rem;

              @include media-breakpoint-down(md) {
                margin-top: 1.2rem;
                padding: 1rem 2rem;
                font-size: 1.2rem;
                line-height: 1;
              }
            }
          }
        }
      }
    }

    &-view {

      .category-hero-banner-container {

        @include media-breakpoint-up(md) {
          min-height: 320px;
          margin: 0 auto;
          max-width: 1570px;
        }

        .breadcrumbs {
          margin: 0 0 2.8rem;

          @include media-breakpoint-up(md) {
            margin: 0 auto 7.7rem;
          }
        }

        .product-count {
          display: none;
        }
      }
    }

    &-description {
      background-color: rgba($gray-500, 0.1);
      padding: 4.2rem 6.6rem 2.9rem;
      margin: 0;

      @include media-breakpoint-up(md) {
        padding: 7.1rem 6.6rem 5.9rem;
      }

      p {
        max-width: 702px;
        margin: 0 auto;
        text-align: center;
        line-height: 2.8rem;
        font-size: 1.6rem;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }
      }

      img {
        height: auto !important;
      }
    }

    &-featured-content {
      max-width: 1570px;
      margin: 0 auto;

      .book-banner {
        margin-bottom: 0;
        background-color: $gray-100;
        background-size: cover !important;

        .row-full-width-inner {
          padding: 6rem 0 6.4rem !important;
          margin: 5.7rem auto 7.9rem;

          div[data-content-type='text'] {

            p + p {
              margin-top: 2.2rem;
            }

            a {

              &[title='Learn More'] {
                font-size: 1.4rem;
                line-height: 1;
                color: $black;
                font-weight: 700;
                display: inline-flex;
                align-items: center;
                padding-left: 2rem;
                padding-right: 5.5rem;
                position: relative;

                &::after {
                  content: '';
                  background: url('../images/icon-arrow-right.svg') no-repeat;
                  background-size: contain;
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  right: 2.5rem;
                  transition: all 0.2s ease-in-out;
                }

                &:hover {
                  color: $primary;

                  &::after {
                    background: url('../images/icon-arrow-right-primary.svg') no-repeat;
                    background-size: contain;
                    right: 0;
                  }
                }
              }
            }
          }
        }

        h2 {
          line-height: 4.2rem;
          padding: 0 1rem;
          margin-bottom: 2.3rem;

          &::before {
            content: unset;
          }
        }

        .pagebuilder-button-secondary {
          height: 44px;
          width: 163px;
          font-size: 1.4rem;
          margin-top: 1.6rem;
        }
      }
    }

    &-child-categories {
      background-color: rgba($gray-500, 0.1);
      padding: 0 2.3rem;

      @include media-breakpoint-up(md) {
        padding: 0 2.8rem 8rem;
      }

      h2 {
        margin-bottom: 2.2rem;
        padding: 0 6rem;
        line-height: 3.8rem;

        @include media-breakpoint-up(lg) {
          margin-bottom: 5.6rem;
        }
      }

      .category-name {
        margin: 2.1rem 0 0;
      }

      .category-image {
        height: 100px;
        margin: 0;

        img {
          max-height: 100%;
          object-fit: contain;
        }
      }

      .category-child-container {
        max-width: 1067px;
        margin: 0 auto;
        text-align: center;

        ul {
          margin: 0;

          .category-child {
            margin-bottom: 3rem;

            @include media-breakpoint-up(lg) {
              margin-bottom: 3.8rem;
            }

            .category-block {
              background-color: $white;
              border-radius: 4px;
              border: 4px solid $white;
              transition: all 0.075s ease-in-out;
              text-decoration: none;
              width: 100%;
              margin: 0 auto;
              padding: 5rem 0;

              @include media-breakpoint-up(lg) {
                padding: 7.6rem 0;
                max-width: 325px;
              }

              &:hover {
                border-color: $primary;
              }

              span {
                text-align: center;
                line-height: 1;
              }

              .btn-secondary {
                max-width: 180px;
                width: 100%;
                margin: 3rem auto 0;
              }
            }
          }
        }
      }

      .btn-primary {
        width: 219px;
        font-size: 1.8rem;
        line-height: 1;
        padding: 1.9rem 0;
        margin: 1.3rem 0 1.8rem;

        @include media-breakpoint-up(lg) {
          margin: 4.2rem 0 0;
        }
      }
    }
  }

  .book-service-container {

    @include media-breakpoint-up(md) {
      padding: 1.8rem 2.8rem;
    }

    .h2 {
      margin: 4.6rem 0 4rem;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .view-all .pagebuilder-button-secondary {
      margin: 4.3rem 0 3.6rem;

      @include media-breakpoint-up(md) {
        margin: 3.6rem 0;
      }
    }
  }

  .recent-posts-wrapper {
    margin: 3.8rem 0 0 auto;
    padding-left: 0;

    @include media-breakpoint-up(sm) {
      padding-left: 2.2rem;
    }

    @include media-breakpoint-up(md) {
      margin: 4.5rem 0 0 auto;
    }

    @media (min-width: 1500px) {
      padding-left: 0;
      margin-left: 9.7rem;
    }

    .amblog-post {

      @include media-breakpoint-up(sm) {
        padding-right: 2.2rem;
      }
    }

    .amblog-widget-container {
      margin-bottom: 0 !important;
    }
  }
}

.page-products:not(.page-layout-category-landing) {

  .page-main {
    max-width: 1493px;

    @include media-breakpoint-down(md) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .columns {
    display: flex;
    position: static;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }

  .sidebar-main {
    float: none;
    order: 0;

    @include media-breakpoint-up(lg) {
      flex: 1 2 326px;
      max-width: 326px;
      min-width: 200px;
      width: 100%;
    }
  }

  .products {
    margin-top: 2rem;
    margin-bottom: 4.4rem;
  }

  .column.main {
    float: none;
    order: 1;
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex: 1 1 1127px;
      max-width: 1127px;
      padding-left: 2.06rem;
    }

    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }
  }
}
