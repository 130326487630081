.cms-set-my-vehicle-confirmation {

  .breadcrumbs {
    margin-bottom: 1.3rem;
  }

  .page-top {

    .setvehicle-confirm-message .message > span {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      column-gap: 0.3rem;
    }

    &[data-content-type=row][data-appearance=full-width] > .row-full-width-inner {
      max-width: 1500px;
      padding: 0 2rem;
    }

    h1 {
      font-weight: 400;
      text-align: center;
      margin: 2rem 0 5rem;

      &::before {
        @extend %icon-check;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
      }
    }
  }

  .book-banner {
    margin-bottom: 0;

    .row-full-width-inner {
      max-width: 412px !important;
      padding: 5.9rem 1.5rem;
    }

    h2 {
      font-size: 2.5rem;
      max-width: 191px;
      margin: 0 auto 3rem;

      &::before {
        display: none;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 1.5;
      max-width: 210px;
      margin: 0 auto 3rem;
    }

    .pagebuilder-button-secondary {
      max-width: 220px;
      margin: auto;
    }
  }

  @include media-breakpoint-down(md) {

    .find-a-store {
      margin-bottom: 5rem;
    }
  }
}
