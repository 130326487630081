.sidebar-main {
  padding-right: 0;
}

.block.filter {
  width: 100%;
}

.filter-content {

  .block-subtitle {
    background-color: $black;
    color: $white;
    font-size: 1.6rem;
    padding: 1.5rem 1.2rem;
    border-radius: 0.4rem;
    width: 100%;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      padding: 0.9rem 0;

      &::before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        margin-left: 0.55rem;
        margin-right: 0.55rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M14.816 6.375a2.24 2.24 0 0 0-4.224 0H5.204v1.5h5.388c.313.899 1.16 1.502 2.112 1.502s1.799-.603 2.112-1.502h3.888v-1.5zm-2.112 1.5a.75.75 0 1 1 0-1.5.75.75 0 1 1 0 1.5zm-4.5 1.5a2.25 2.25 0 0 0-2.112 1.5h-.888v1.5h.888a2.24 2.24 0 0 0 4.224 0h8.388v-1.5h-8.388a2.25 2.25 0 0 0-2.112-1.5zm0 3a.75.75 0 1 1 0-1.5.75.75 0 1 1 0 1.5zm6.75 1.5a2.25 2.25 0 0 0-2.112 1.5H5.204v1.5h7.638c.313.899 1.16 1.502 2.112 1.502s1.799-.603 2.112-1.502h1.638v-1.5h-1.638a2.25 2.25 0 0 0-2.112-1.5zm0 3a.75.75 0 1 1 0-1.5.75.75 0 1 1 0 1.5z'/%3E%3C/svg%3E");
        transform: rotate(-90deg);
        filter: invert(1);
      }
    }
  }

  .filter-options-title {
    color: $black;
    background-color: $gray-100;
    font-size: 1.6rem;
    padding: 1.7rem 1.2rem;
    border-radius: 0.4rem;
    margin-bottom: 2rem;
    text-transform: capitalize;
  }

  ul.items,
  ol.items {
    display: flex;
    flex-wrap: wrap;

    li.item {
      padding-left: 0;
      margin: 0.5rem 1rem;
      width: 100%;

      .item {
        margin-left: 2rem !important;
      }

      &.-is-expanded.-is-collapsible {
        margin-left: 1rem !important;
      }

      &.selected-option {
        order: -1;
        display: block !important;

        li {
          display: block !important;
        }

        &.page-option {

          > a,
          > input,
          > span {
            cursor: default;
          }

          > .amshopby-choice::after {
            background-color: $gray-600 !important;
          }
        }
      }

      .am-collapse-icon {
        display: none;
      }

      > a {
        text-decoration: none;
        padding-left: 3.19rem !important;
        line-height: 3.19rem;

        .label {
          font-size: 1.4rem;
          color: $black;
          font-weight: normal;
        }

        .count {
          display: none;
        }

        &.amshopby-link-selected {

          + ul.items {
            display: block !important;
          }
        }
      }

      .amshopby-choice {
        left: 0 !important;

        &::before {
          width: 25px;
          height: 25px;
          border-radius: 0.31rem;

          &:hover {
            border-color: $black !important;
          }
        }

        &::after {
          background-color: $black !important;
          background-size: 19px;
          height: 25px;
          width: 25px;
          border-radius: 0.31rem;
        }
      }
    }

    li.item.-is-collapsible.-is-by-click.visible {
      display: list-item !important;
    }
  }

  .filter-options-content {
    margin: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-300;
  }

  .am-show-more {
    text-decoration: underline;

    &::before {
      display: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.amshopby-filter-current {
  display: flex;
  padding: 1.5rem 2.7rem;
  margin-top: 2rem;
  background-color: rgba($gray-500, 0.102);
  border-radius: 4px;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;
  order: 6;
  width: 100%;

  @include media-breakpoint-down(lg) {
    order: 4;
    margin: 0 -2rem 2rem;
    width: calc(100% + 4rem);
  }

  .filter-current-subtitle {
    font-weight: 700;
    margin: 0;
  }

  .amshopby-items {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 1.5rem;
  }

  .amshopby-item.item {
    background-color: $white;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 1;
    min-width: 120px;
    margin: 0;
    padding: 0.8rem 3rem 0.8rem 0.8rem;

    .amshopby-remove {
      left: auto;
      right: 0.5rem;
      width: 2.4rem;
      height: 2.4rem;

      &::before,
      &::after {
        background-color: rgba($headings-color, 0.4);
        width: 60%;
        height: 2px;
      }
    }

    .amshopby-filter-name {
      display: none;
    }
  }
}
