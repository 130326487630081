.storelocator {
  padding: 0 1.7rem;

  &-filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1.5rem 2rem;
    background-color: rgba($gray-500, 0.1);

    &.state-filter {
      margin-top: 2rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 1.9rem;
      padding: 1.8rem 2.1rem;
    }

    .filters-toggle {
      white-space: nowrap;
      font-size: 1.4rem;
      font-weight: 700;
      border: none;
      padding: 0;
      background-color: transparent;
      color: inherit;

      &:active,
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
        pointer-events: none;
      }

      img {
        margin-left: 1.3rem;
      }

      &:not(.collapsed) {

        img {
          transform: rotate(180deg);
        }
      }
    }

    &-list {
      width: 100%;

      .attributes {
        justify-content: start;
        gap: 1rem;
        margin-top: 2.8rem;

        @include media-breakpoint-up(lg) {
          gap: 1.6rem;
          margin-top: 0;
        }

        @include media-breakpoint-down(lg) {
          justify-content: center;
        }

        .attribute {

          @include media-breakpoint-down(lg) {
            margin-bottom: 0.9rem;
          }

          .link {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: $white;
            width: 110px;
            height: 36px;
            font-size: 1.2rem;
            line-height: 1;
            padding: 1.3rem 1rem;

            @include media-breakpoint-up(lg) {
              width: 130px;
              height: 44px;
              font-size: 1.4rem;
            }

            &:focus,
            &:hover {
              background-color: $secondary;
              color: $white;
            }

            &.active {
              background-color: $secondary;
              color: $white;
            }
          }
        }
      }
    }
  }
}
