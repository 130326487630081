
.ammenu-menu-wrapper {
  margin-bottom: 0;
  background-color: $black;
}

.ammenu-nav-sections.-topmenu {
  background-color: $black;
  position: relative;
  z-index: 100;

  .ammenu-link {
    color: $white !important;
    background-color: transparent !important;
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.7rem 12px !important;

    @include media-breakpoint-up(xl) {
      font-size: 1.8rem !important;
    }
  }

  .ammenu-item:hover .ammenu-link {
    background-color: $white !important;
    color: $primary !important;
    transition-delay: 0.3s;
  }

  .ammenu-main-container {
    background-color: $black !important;
    padding: 0;

    .ammenu-items.ammenu-items.ammenu-items.-root {
      max-width: 1522px;
      justify-content: space-between;

      @include media-breakpoint-down(xl) {
        padding-inline: 1.2rem;
      }

      @include media-breakpoint-only(xl) {
        padding-inline: 24px;
      }
    }

    [data-content-type='row'] {
      max-width: none;
    }

    .ammenu-submenu-wrapper {
      padding: 0;
      border-radius: 0 0 1rem 1rem;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .ammenu-item:hover > .ammenu-submenu-wrapper {

      @include media-breakpoint-up(md) {
        display: block;
        transform-origin: top;
        animation: showSubmenu 0.4s forwards;
      }
    }

    .block-static-block.widget {
      margin-bottom: 0;
    }
  }

  .ammenu-category-columns {
    padding: 30px 30px 30px 80px;

    .ammenu-column {

      .ammenu-title {
        font-weight: 400;
        font-size: 1.6rem;
        text-decoration: none;
        color: $body-color !important;
        outline: none;

        &:hover {
          color: $primary !important;
        }
      }
    }

    .ammenu-column:not(:last-child) {
      padding-right: 0.5rem;
    }

    &.-col-2 .ammenu-column {
      width: auto;
      min-width: calc(100% / 2);
    }

    .ammenu-column:first-child {
      width: 100%;
      margin-bottom: 1.7rem;
      line-height: 1;

      .ammenu-title {
        font-weight: 700;
      }
    }
  }

  .ammenu-submenu-block {

    .pagebuilder-column .widget.block {
      border-left: solid 1px $border-color;
    }

    .pagebuilder-column-line {
      justify-content: space-between;
    }
  }
}

// Buying guide that appears in the mega menu
.doc-list {

  @include media-breakpoint-up(lg) {
    padding-left: 30px;
  }

  [data-content-type='text'] {
    padding: 0 30px;
  }

  p {
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 3.7rem;
    margin-top: 3rem;
    color: $headings-color;

    a {
      text-decoration: none;

      &:not(button):hover {
        color: $primary;
      }
    }

    @include media-breakpoint-down(lg) {
      margin-top: 4rem;
      margin-bottom: 4.3rem;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 1.6rem;
      margin-bottom: 2.5rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 3.5rem;
      }

      a {
        text-decoration: none;
        color: $headings-color;

        &::before {
          content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.16 18.354"><path d="M0 0h17.16v18.35H0Zm15.25 1.669H1.907v15.016H15.25Z" /><path d="M14.315 6.675H7.641V5.006h6.674ZM14.1 10.012H3.615V8.343H14.1ZM14.1 13.349H3.615V11.68H14.1Z" /></svg>');
          margin-right: 1rem;
          display: inline-block;
          width: 17.16px;
          height: 18.354px;
          vertical-align: middle;
          margin-bottom: 3px;
        }

        &:hover {
          color: $primary;

          &::before {
            content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.16 18.354"><path d="M0 0h17.16v18.35H0Zm15.25 1.669H1.907v15.016H15.25Z" fill="#{$primary-icon-color}" /><path fill="#{$primary-icon-color}" d="M14.315 6.675H7.641V5.006h6.674ZM14.1 10.012H3.615V8.343H14.1ZM14.1 13.349H3.615V11.68H14.1Z" /></svg>');
          }
        }
      }
    }
  }
}

.mega-menu-cta {

  [data-content-type='text'] {
    min-height: 22.2rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%) 0% 0% no-repeat padding-box;
    padding: 0 44px 0 57px;

    @include media-breakpoint-up(lg) {
      min-height: 33.3rem;
      display: flex;
    }

    @include media-breakpoint-down(lg) {
      padding: 3rem 3.6rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    p {
      font-size: 2rem;
      line-height: 2.5rem;
      color: $white !important;
      font-weight: 700;

      @include media-breakpoint-up(lg) {
        margin-top: auto !important;
        margin-bottom: 3.9rem !important;
      }

      @include media-breakpoint-down(lg) {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      white-space: nowrap;

      @include btn();
      @include button-variant(
        $primary,
        $primary,
        $color: $white,
        $active-border: $primary,
        $active-background: $primary,
        $active-color: $primary,
        $hover-background: shade-color($primary, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($primary, $btn-hover-bg-shade-amount),
        $hover-color: $white
      );
    }
  }
}

.ammenu-nav-sections.-sidebar {
  padding-bottom: 0;
  width: calc(100vw - 5.9rem);
  z-index: 902;

  &.in-submenu {

    .mobile-links-receiver,
    .ammenu-menu-title {
      display: none;
    }
  }

  .ammenu-menu-title {
    margin: 1.6rem 1.5rem 0 1.5rem;
    padding: 1.9rem 2.4rem;
    background-color: $black;
    border-radius: 0.4rem;

    .ammenu-title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1;
      color: $white !important;
    }

    .ammenu-button.-icon.-close {
      display: none;
    }
  }

  .ammenu-drill-navigation {
    margin: 1.6rem 1.5rem 0 1.5rem;
    background-color: $black;
    color: $white !important;
    border-radius: 0.4rem;
    border: none;

    .ammenu-button {
      text-align: center;
      font-weight: 700;
      font-size: 1.6rem;
      justify-content: center;
      position: relative;
      padding: 1.9rem 2.4rem;

      .ammenu-icon-block {
        position: absolute;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background-image: url('../images/icon-right-white.svg');
        background-position: center center;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;

        svg {
          display: none;
        }
      }
    }
  }

  .ammenu-tabs-list {
    display: none;
  }

  .ammenu-current-title.ammenu-current-title {
    display: none;
  }

  .-all-link .ammenu-text-block {
    font-weight: 700;
  }

  .ammenu-item {
    position: relative;

    .ammenu-link {
      text-decoration: none;
      border-bottom: solid 1px $border-color !important;
      margin-left: 1.8rem;
      padding: 2rem 2.2rem !important;
      font-size: 1.6rem;
      position: relative;
      color: $dark-text !important;
    }

    &.category-item .ammenu-link {
      font-size: 2.5rem;
      font-weight: 400 !important;
    }

    .link-toggle {
      content: ' ';
      display: inline-block;
      position: absolute;
      width: 75px !important;
      height: 75px !important;
      margin-right: 0;
      background: url('../images/icon-right.svg');
      background-position: center center;
      background-repeat: no-repeat;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
      border-bottom: none !important;
    }

    &.category-item:last-child .ammenu-link {
      border-bottom: none !important;
    }
  }

  .drill-submenu-content {

    [data-content-type='ammega_menu_widget'] {
      display: none !important;
    }

    @include media-breakpoint-down(lg) {

      .pagebuilder-column-line {
        flex-wrap: wrap;
      }

      .pagebuilder-column {
        width: 100% !important;
      }
    }

    .block-static-block.widget {
      margin-bottom: 0;
    }
  }

  .ammenu-drill-section.ammenu-drill-section.ammenu-drill-section {
    padding-bottom: 0;

    @include media-breakpoint-down(lg) {

      .ammenu-items.-drill.Tow.Load {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(lg) {

  .custom-mega-menu-desktop {
    display: none;
  }
}

@include media-breakpoint-up(lg) {

  .custom-mega-menu-mobile {
    display: none !important;
  }
}

.ammenu-menu-wrapper .ammenu-menu-overlay {
  background-color: rgba($black, 0.7);
  z-index: 901;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    top: 2rem;
    right: 0.5rem;
    background-image: url('../images/icon-close.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}

@include media-breakpoint-up(lg) {

  .ammenu-item.category-item::before {
    content: '';
    display: block;
    background: rgba($black, 0.5);
    height: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    left: auto;
    transition: none;
  }

  .ammenu-item.category-item:hover::before {
    height: 100%;
    transition-property: all;
    transition-duration: 0s;
    transition-delay: 0.4s;
  }

  .ammenu-header-container.page-header {
    background-color: $white;
    position: relative;
    z-index: 120;
  }
}

@keyframes showSubmenu {

  0% {
    transform: scaleY(0);
  }

  99% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
