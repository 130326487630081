.product-items .product-item-info {
  width: 100%;
  margin-bottom: 3.1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 5rem;
  }

  .product-item-link {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.72;
    color: inherit;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 1.34;
    }
  }

  .product-item-details {
    padding-top: 0;
    padding-inline: 1.4rem;

    @include media-breakpoint-up(md) {
      padding-top: 0.9rem;
      padding-inline: 2rem;
    }
  }

  .fitment-details .outcome {
    font-weight: 700;
  }

  .price-box {

    @include media-breakpoint-down(md) {
      margin: 0.2rem 0 1.2rem;
    }

    .price,
    .price-subtract {
      font-weight: 400;
      font-size: 1.4rem;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }
    }

    .price-label {

      @include visually-hidden;
    }

    .price-layout {
      display: flex;
      margin-bottom: 0.2rem;
    }

    .old-price {
      margin-right: 1rem;

      @include media-breakpoint-up(md) {
        margin-right: 1.6rem;
      }
    }

    .special-price {
      color: $primary;

      .price {
        font-weight: 700;
      }
    }
  }

  .product-image {
    position: relative;
    z-index: 0;
    margin-bottom: 0.3rem;
  }
}

.label-sale {
  position: absolute;
  bottom: 5px;
  left: 13px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background: $primary;
  color: $white;
  border-radius: $border-radius-pill;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 0.88;
  font-size: 1.1rem;

  @include media-breakpoint-up(md) {
    width: 55px;
    height: 55px;
    left: 18px;
    font-size: 1.6rem;
  }
}
