.catalog-product-view {

  .breadcrumbs {
    margin-bottom: 2rem;
  }

  .vehicle-fits-message {
    max-width: 1502px;
    margin: auto;
    padding: 0 2rem;

    .message.message {
      margin-bottom: 2rem;
    }
  }

  .page-wrapper {
    background-color: rgba($gray-500, 0.1);
  }

  .page-main-container {
    overflow-x: hidden;

    .page-title-wrapper .page-title {
      margin-top: 0;
      font-size: 2.2rem;
      line-height: 1.1;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        font-size: 2.8rem;
      }

      @include media-breakpoint-down(md) {
        padding: 0 3.2rem;
      }
    }

    > .page-title-wrapper {

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .page-main {
    max-width: 100%;
    overflow-x: hidden;
    padding: 0;
  }

  .column.main {
    max-width: 1232px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.6rem;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .product-mobile-top {
    order: -2;
    margin-bottom: 1.9rem;
    padding-inline: 1rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .product-desktop {

    @include media-breakpoint-down(md) {

      .page-title-wrapper,
      .product-info-price,
      .disclaimer-text {
        display: none;
      }
    }
  }

  .product-info-main {
    flex: 1 0 0;

    @include media-breakpoint-up(md) {
      padding-left: 2.8rem;
      width: auto;
    }

    @include media-breakpoint-only(xl) {
      padding-left: 5.8rem;
    }

    .page-title-wrapper .page-title {
      margin-top: 0;
      margin-bottom: 0.4rem;
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 1.51;

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    .product.attribute.sku {
      color: inherit;

      .type {
        margin-right: 0.1rem;

        &::after {
          content: ':';
        }
      }

      > .value {
        display: inline;
        vertical-align: baseline;
      }
    }

    .price-label {

      @include visually-hidden;
    }

    .price-layout {
      display: flex;
    }

    .price-box {
      margin-bottom: 0.2rem;
      font-weight: 700;
      font-size: 2.5rem;

      @include media-breakpoint-down(md) {
        margin-top: 0.8rem;
      }

      .price-container,
      .price-wrapper,
      .price {
        font-weight: inherit;
        font-size: inherit;
      }

      .price-subtract {
        margin-top: 0.4rem;
        font-weight: 400;
        font-size: 1.6rem;
      }
    }

    .old-price {
      margin-right: 2.4rem;
      font-weight: 400;
    }

    .special-price {
      margin: 0;
      color: $primary;
    }

    .enquire-product {
      margin-top: 1.6rem;
      background-color: $white;
      border-left: $primary 8px solid;
      padding: 2.8rem 2.5rem 2.8rem 2rem;
      border-radius: 0.4rem 0.4rem;
      line-height: 1.839;
      font-size: 1.2rem;
      box-shadow: 0 0 20px rgba($black, 0.05);

      @include media-breakpoint-down(md) {
        margin-top: 2.9rem;
      }

      h2 {
        font-size: 2rem;
      }

      .btn {
        max-width: 12.6rem;
        width: 100%;
      }

      .pagebuilder-column-line {
        flex-direction: column;
      }

      .pagebuilder-column {
        max-width: 382px;
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  .product.media {
    position: relative;
    z-index: 0;
    max-width: 400px;
    flex: 0 1 400px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-only(lg) {
      max-width: 450px;
      flex-basis: 450px;
    }

    @include media-breakpoint-only(xl) {
      max-width: 543px;
      flex-basis: 543px;
    }

    @include media-breakpoint-up(md) {
      order: -1;
      flex-shrink: 0;
    }

    .label-sale {
      left: auto;
      bottom: auto;
      top: 2.9rem;
      right: 2.9rem;

      @include media-breakpoint-down(md) {
        top: 1.8rem;
        right: 2.1rem;
        width: 55px;
        height: 55px;
        font-size: 1.6rem;
      }
    }
  }

  .newsletter-container {
    margin-top: 0;
  }

  .product-enquiry-modal {

    .modal-header {
      padding: 3.3rem 3rem 0;

      @include media-breakpoint-up(md) {
        padding: 6rem 8rem 0;
        text-align: center;
      }
    }

    .modal-title {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 1;
      border: 0;
      padding: 0;

      @include media-breakpoint-up(md) {
        font-size: 4rem;
      }
    }

    .action-close {
      width: 3.2rem;
      height: 3.2rem;
      padding: 0;
      background: url(../images/icon-close-black.svg) no-repeat center;
      background-size: cover;
      top: 3rem;
      right: 2rem;
      border: none;
      background-color: transparent;
      outline: 0;

      @include media-breakpoint-up(md) {
        width: 5.6rem;
        height: 5.6rem;
        right: 3rem;
      }
    }

    .modal-inner-wrap {
      max-width: 360px;
      width: 90%;
      border-radius: 4px;

      @include media-breakpoint-up(md) {
        max-width: 935px;
      }
    }

    .modal-content {
      padding: 4rem 3rem;

      @include media-breakpoint-up(md) {
        padding: 6rem 8rem;
      }
    }

    .booking-form-container {
      background-image: none;
      gap: 5rem;
      margin: 0;

      &::before,
      &::after {
        display: none;
      }

      .submit-posttext {
        display: flex;
        align-items: flex-start;
        font-size: 1.4rem;
        justify-content: center;
        line-height: 1.58;
        margin-top: 3.2rem;

        svg {
          flex-shrink: 0;
        }

        p {
          max-width: 419px;
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }

    .container {
      --gutter-x: 0;
    }

    .section {
      padding: 0;
    }

    fieldset {
      box-shadow: none;
      padding: 0;

      &.row {
        padding: 0;
      }
    }
  }

  .part-fits-container {
    min-height: 80px;
    background: $white;
    margin: 1.4rem 0;
    font-size: 1.8rem;
    box-shadow: 0 0 30px rgba($black, 0.1);
    border-radius: 0.4rem;
    overflow: hidden;

    .find-products-pdp {
      width: 100%;

      @include media-breakpoint-only(xs) {
        background-size: 100% !important;
      }
    }

    .fit-text {
      font-size: 2rem;
      font-weight: 600;
    }

    .part-fits {
      text-align: center;
      flex: 1;
      gap: 1.4rem;
      padding: 2rem;
      line-height: 1;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &::before {
        @extend %icon-check;

        margin: 0;
      }

      &.not-fit {
        background: $primary;
        color: $white;

        &::before {
          background-image: url('../images/icon-close-circle-white.svg');
        }
      }
    }
  }

  .disclaimer-text {
    font-size: 1rem;
    color: $gray-500;
  }
}

.toolbar-products {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  position: relative;
  justify-content: flex-end;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    padding: 0;
  }

  &.toolbar-top {

    .show-all-limiter {

      @include media-breakpoint-down(lg) {
        bottom: 0;
      }
    }
  }

  &.toolbar-bottom {
    justify-content: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 6rem;
    }
  }

  .show-all-limiter {
    order: 3;
    position: absolute;
    right: 0;

    a {
      color: $body-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    & + .pages {
      padding-left: 7rem;
      padding-right: 7rem;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        padding-left: 7rem;
      }

      @media (max-width: 370px) {
        justify-content: flex-end !important;
      }
    }
  }

  .pages {
    order: 5;

    &.pages.pages.pages {
      float: none !important;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-down(lg) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        position: static;
        transform: none;
      }
    }

    #paging-label {
      display: none;
    }

    .pages-item-next,
    .pages-item-previous {
      display: none;
    }
  }

  &::before,
  &::after,
  .modes {
    display: none !important;
  }

  .limiter.limiter.limiter {
    float: none;
    order: 1;
    position: absolute;
    right: 0;
    font-size: 1.8rem;

    > .control {
      display: inline-flex;
      gap: 1rem;
      margin: 0 0.5rem;
    }
  } 

  .not-selected-limiter {
    text-decoration: none;
    color: $red;
  }

  .selected-limiter {
    font-weight: bold;
    color: $black;
  }

  .limiters:hover {
    text-decoration: none;
    color: #333;
  }

  .toolbar-amount {
    order: 3;
    padding-top: 0;
    display: none;
  }

  .toolbar-sorter {
    float: none;
    order: 1;
    padding: 0;

    .sorter-action,
    .sorter-label {
      display: none !important;
    }

    .sorter-options {
      margin: 0;
      color: $gray-500;
    }

    @include media-breakpoint-up(lg) {

      .refine-by {
        display: none;
      }
    }

    @include media-breakpoint-down(lg) {
      width: calc(100% + #{$grid-gutter-width});
      margin-left: calc($grid-gutter-width / 2);
      margin-right: calc($grid-gutter-width / 2);
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      .refine-by,
      .sorter-options {
        width: calc(50% - 0.6rem);
        height: 60px;
        border: 1px solid $border-color;
        font-size: 1.6rem;
        line-height: 1.6rem;
        padding: 2.2rem 2.5rem;
      }

      .sorter-options {
        margin-right: 1px;
        background-color: transparent;
      }

      .refine-by {
        margin-left: 1px;
        text-align: left;
        position: relative;
        background-color: $black;
        color: $white;
        border-radius: 0.4rem;
        font-weight: 700;

        &::after {
          content: ' ';
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%) rotate(-90deg);
          background-image: url(../images/icon-arrow-down.svg);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          filter: invert(1);
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.product.info.detailed {
  margin-top: 5.7rem;
  margin-bottom: 0;
  width: 100%;

  @include media-breakpoint-down(md) {
    width: calc(100% + 3.2rem);
    margin-top: 4.6rem;
    margin-inline: -1.6rem;
    padding-inline: 1.6rem;
    background-color: $white;
  }

  .product.attribute.description,
  .product.attribute.features {
    max-width: 742px;
  }

  .additional-attributes {
    width: 100%;

    .table-caption {
      position: static;
      display: block;
      clip: auto;
      width: auto;
      height: auto;
      margin: 0;
      color: inherit;
      font-weight: 700;
      font-size: 1.8rem;
      background-color: $black;
      color: $white;
      border-radius: 0.4rem;

      @include media-breakpoint-up(sm) {
        display: table-caption;
        caption-side: top;
      }
    }

    tr:nth-child(odd) {

      th,
      td {
        background-color: rgba($gray-500, 0.1);
      }
    }

    .table-caption,
    > tbody > tr > th,
    > tbody > tr > td {
      padding: 1.1rem 2.9rem !important;
    }

    th {
      width: 1px;
      white-space: nowrap;
    }
  }

  .fiftment-details {

    th {
      background-color: $black;
      color: $white;

      @include media-breakpoint-down(md) {
        min-width: 120px;
      }
    }

    tr th:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    tr th:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    tr:nth-child(odd) {

      td {
        background-color: rgba($gray-500, 0.1);
      }
    }
  }

  @include media-breakpoint-down(md) {

    #vehicles {
      overflow-x: scroll;
    }
  }
}

.product.data.items {

  @include media-breakpoint-down(md) {
    padding-bottom: 10rem;
  }

  &::before,
  &::after {
    display: none;
  }

  > .item {

    &.content {
      position: relative;
      z-index: 0;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        border: none;
        margin-inline: -1.6rem;
        padding: 2.4rem 4.1rem;
      }

      @include media-breakpoint-up(md) {
        margin-top: 6rem;
        padding: 4.2rem 0 6rem;
        border-top-color: $gray-400;
        line-height: 1.75;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          border-top: 1px solid $gray-400;
          width: 100%;
          height: 100%;
          background: $white;
          margin-top: -0.1rem;
        }

        &::before {
          left: 100%;
        }

        &::after {
          right: 100%;
        }

        li {
          margin-bottom: 1.6rem;
        }
      }
    }

    &.title.title.title {
      margin-right: 0.5rem;

      > .switch {
        height: auto;
        padding: 2.1rem 4rem;
        border: none;
        font-weight: 700;
        font-size: 2rem;
        color: $body-color;
        background: none;

        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3rem 3.2rem 3rem 2.5rem;
          background-color: transparent;

          img {
            transform: rotate(90deg);
          }
        }
      }

      &.active {

        @include media-breakpoint-down(md) {

          &::after {
            transform: rotate(180deg) translateY(50%);
          }
        }

        .switch {

          @include media-breakpoint-up(md) {
            background-color: $white;
            border-radius: 4px 4px 0 0;
          }
        }
      }

      @include media-breakpoint-down(md) {
        position: relative;
        z-index: 0;
        margin: 0;
        border-top: 2px solid $border-color;
        scroll-margin-top: 11.3rem;

        &::before {
          content: '';
          position: absolute;
          z-index: -2;
          top: 0;
          left: -1.6rem;
          right: -1.6rem;
          bottom: 0;
          background: $white;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          right: 3.4rem;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='m11.695 15.796-6.53-6.53 1.06-1.06 5.47 5.47 5.47-5.47 1.061 1.06z'/%3E%3C/svg%3E");
        }

        &:first-child {
          border-top: none;
        }

        &.active > .switch {
          border-bottom-color: $dark;

          img {
            transform: rotate(-90deg);
          }
        }
      }

      @include media-breakpoint-up(md) {

        > .switch {
          padding: 0 4.4rem;
          font-size: 1.8rem;
          height: 6rem;
          align-items: center;
          justify-content: center;
          display: flex;

          img {
            display: none;
          }
        }
      }
    }
  }

  [id='tab-label-fitment-specs'],
  [id='fitment-specs'] {
    display: none;
  }
}

.fotorama-item {

  .fotorama__stage__frame,
  .fotorama__thumb-border,
  .fotorama__nav__frame--thumb {
    border-radius: 0.4rem;
  }

  .fotorama__thumb-border {
    margin-top: 0.9rem !important;
    background: none;
    border-color: $primary;
  }

  .fotorama__nav__frame {
    padding: 0.9rem 10px 0.9rem 0 !important;
  }
}

.magnify-lens {
  display: none !important;
}

.magnifier-preview {
  transform: translateX(16px);
  top: 0 !important;
  left: 100% !important;

  @include media-breakpoint-down(lg) {
    display: none !important;
  }

  @include media-breakpoint-only(lg) {
    width: 450px !important;
    height: 450px !important;
  }
}

.video-thumb-icon::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.21 25.21'%3E%3Cpath d='M12.6 0c6.959 0 12.6 5.641 12.6 12.6s-5.641 12.6-12.6 12.6S0 19.559 0 12.6 5.641 0 12.6 0z' fill='%23ef4135'/%3E%3Cpath d='M17 12.5 10 18V7z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  transform: translateX(-5px);
}

.catalog-topnav {
  display: block;

  .block-actions {
    display: block;
  }
}

.part-number {
  word-break: break-all;
}

.fotorama__spinner.fotorama__spinner {
  background-image: none;
  width: 34px;
  height: 34px;
  vertical-align: middle;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  border: 2px solid $primary;
  border-right-color: transparent;
  border-radius: 99px;
  transform: rotate(0deg);
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
