.block.widget.block-products-list {
  background: white;

  h3 {
    margin: 0;
    padding-top: 6rem;
  }

  .products-grid .product-items {
    max-width: 1146px;
    padding: 3rem 2rem 6.6rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2%;
    row-gap: 1.5rem;

    .product-item {
      display: inline-block;
      width: 49%;
      margin: 0;

      @include media-breakpoint-up(md) {
        width: 23.5%;
      }
    }

    .product-item-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;

      .price-box {

        .price-label {
          position: relative !important;
        }
      }

      .view-product {
        margin: auto auto 3rem;
        min-width: 123px;

        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
          padding: 1.2rem;
        }

        @include media-breakpoint-up(lg) {
          min-width: 180px;
        }
      }
    }
  }
}
