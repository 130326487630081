.text-sm {
  font-size: 1.1rem;
}

body {
  font-family: $font-family-base;
}

ul,
ol {
  list-style: disc;
}

@include media-breakpoint-down(md) {

  h1,
  .h1 {
    font-size: 3.2rem;
  }

  h2,
  .h2 {
    font-size: 2.5rem;
  }

  h3,
  .h3 {
    font-size: 2rem;
  }
}
