/* Sub pages for book a service */

%book-style {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    height: 6rem;
    font-size: 1.8rem;
  }
}

%icon-check {
  content: '';
  display: block;
  width: 39px;
  height: 39px;
  margin: -0.2rem auto 2rem;
  background-image: url('../images/icon-check-circle.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

[class*='cms-book-a-service-'] {

  .columns .column.main {
    padding-bottom: 0;
  }

  .newsletter-container {
    margin-top: 0;
  }
}

.pagebuilder-button-primary {

  .book-a-service-header &,
  .book-text &,
  .book-cta-single &,
  .book-cta-large & {
    @extend %book-style;

    width: 15.4rem;

    @include media-breakpoint-up(md) {
      width: 21.9rem;
    }
  }
}

.book-a-service-header {
  text-align: center;
  max-width: 434px;
  margin: 2.2rem auto 5rem;

  @include media-breakpoint-only(xs) {
    max-width: calc(298px + (#{$grid-gutter-width} * 2));
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    margin-bottom: 4.6rem;
  }

  h1 {
    font-weight: 400;
    line-height: 1.3;

    @include media-breakpoint-only(xs) {
      font-size: 3.2rem;
    }
  }

  .pagebuilder-button-primary {
    margin-top: 2rem;
  }
}

.book-a-service-check {
  padding-bottom: 10.4rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 6rem;
  }

  .hide {
    display: none;
  }

  h2 {
    margin-top: 2.8rem;
    margin-bottom: 4.9rem;

    @include media-breakpoint-only(xs) {
      margin-top: 2.4rem;
      margin-bottom: 2.7rem;
      font-size: 2.5rem;
      max-width: none;
    }
  }

  .book-service {

    [data-content-type='text'] {
      max-width: 290px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .pagebuilder-column {
      gap: 2.5rem;

      @include media-breakpoint-only(md) {
        width: calc(50% - 2rem) !important;
        max-width: 344px;
      }

      @include media-breakpoint-down(md) {
        gap: 1.6rem;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
      }

      h3 {
        max-width: 300px;

        @include media-breakpoint-up(md) {
          max-width: 191px;
        }
      }
    }
  }

  .pagebuilder-column-line.pagebuilder-column-line {

    @include media-breakpoint-only(md) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include media-breakpoint-only(xs) {
      gap: 2.3rem;
    }
  }

  .pagebuilder-column-group {
    max-width: 1480px;
  }

  .pagebuilder-column {
    padding-bottom: 3rem;
  }

  h3 {
    max-width: 250px;
    padding-left: 2rem;
    padding-right: 2rem;

    &::before {
      @extend %icon-check;
    }
  }

  &.book-a-service-top a {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    color: inherit;
    padding-left: 0.2rem;

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='m15.594 21.06-8.707-8.707 1.414-1.414 7.293 7.293 7.293-7.293 1.414 1.414z'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      color: $primary;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23EF4135'%3E%3Cpath d='m15.594 21.06-8.707-8.707 1.414-1.414 7.293 7.293 7.293-7.293 1.414 1.414z'/%3E%3C/svg%3E");
      }
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }
}

.book-banner {
  max-width: 1570px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5.6rem;

  @include media-breakpoint-only(xs) {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    margin-bottom: 3.6rem;
  }

  .row-full-width-inner {
    background-color: $white;
    text-align: center;
    border-radius: 0.4rem;
    max-width: 546px !important;
    margin: 46px auto;
    padding: 4.6rem $grid-gutter-width;
  }

  [data-content-type='text'] {
    max-width: 381px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      max-width: 280px;

      p {
        margin-top: 0;
      }
    }
  }

  h2 {
    margin-bottom: 1.6rem;

    &::before {
      @extend %icon-check;
    }

    @include media-breakpoint-only(xs) {
      font-size: 2.5rem;

      + p {
        margin-bottom: 0;
      }
    }
  }

  p {
    line-height: 1.75;

    + p {
      margin-top: 2.8rem;
    }
  }

  .pagebuilder-button-secondary {
    @extend %book-style;

    width: 30rem;
    margin-top: 1.4rem;

    @include media-breakpoint-down(md) {
      width: 24.4rem;
    }
  }
}

.book-text {
  text-align: center;
  max-width: calc(702px + (#{$grid-gutter-width} * 2));
  margin: 7.5rem auto 7.3rem;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  line-height: 1.75;

  @include media-breakpoint-only(xs) {
    margin-top: 3rem;
    margin-bottom: 6.1rem;
    padding-left: 3.4rem;
    padding-right: 3.4rem;
  }

  h2 {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    line-height: 1.3;

    @include media-breakpoint-only(xs) {
      max-width: 274px;
      margin-bottom: 1.6rem;
      font-size: 2.5rem;
      line-height: 1.52;
    }
  }

  .pagebuilder-button-primary {
    margin-bottom: 7.6rem;

    @include media-breakpoint-only(xs) {
      margin-bottom: 4.4rem;
    }
  }

  .lead {
    line-height: 1.4;
  }

  p {
    margin-top: 2rem;
  }
}

.book-cta-single {
  text-align: center;
  position: relative;
  z-index: 0;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include media-breakpoint-down(md) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 4px;
    background: $gray-100;
    z-index: -1;
  }
}

.book-blocks {
  text-align: center;
  margin-top: -8rem;
  margin-bottom: -8rem;
  padding-top: 8rem;
  padding-bottom: 11.2rem;
  background-color: $light;

  @include media-breakpoint-up(md) {
    margin-inline: auto;
    max-width: 1570px;
    padding-left: 2rem;
    padding-right: 2rem;

    .row-full-width-inner.row-full-width-inner.row-full-width-inner {
      max-width: 1147px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem;
    padding: 7.4rem 2.6rem 5.4rem;
  }

  h2 {
    margin-bottom: -0.8rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 7.2rem;
    }

    @include media-breakpoint-only(xs) {
      max-width: 186px;
      margin-left: auto;
      margin-right: auto;
      font-size: 2.5rem;
    }
  }

  .pagebuilder-column-group:not(.book-block-reverse) {

    @include media-breakpoint-down(md) {
      min-height: 0;
    }

    @include media-breakpoint-up(md) {

      .pagebuilder-column ~ .pagebuilder-column [data-content-type='text'] {
        margin-right: 0;
      }
    }
  }

  .pagebuilder-column-line {

    @include media-breakpoint-up(md) {
      margin-top: 10rem;
    }
  }

  .pagebuilder-column {
    min-height: 426px;
    justify-content: center !important;
    border-radius: 0.5rem;
    background-position: center !important;

    @include media-breakpoint-down(md) {
      margin-bottom: 4.3rem;
      min-height: 218px;
    }

    @include media-breakpoint-only(xs) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }

    @include media-breakpoint-only(xl) {

      &[class*='background-image'] {
        min-width: 603px;
      }
    }
  }

  .book-block-reverse .pagebuilder-column {

    @include media-breakpoint-up(md) {

      &:first-child [data-content-type='text'] {
        margin-left: 0;
      }
    }

    @include media-breakpoint-down(md) {

      &:first-child {
        min-height: 0;
      }

      + .pagebuilder-column {
        order: -1;
      }
    }
  }

  [data-content-type='text'] {
    max-width: calc(452px + (#{$grid-gutter-width} * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    line-height: 1.61;
    font-size: 1.8rem;

    @include media-breakpoint-up(lg) {
      max-width: 452px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  h3 {
    margin-bottom: 2.7rem;
    font-size: 2.5rem;

    @include media-breakpoint-down(md) {
      max-width: 238px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.book-row-testimonials {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-inline: 2.7rem;
  padding-bottom: 11rem;
  background: $white;

  @include media-breakpoint-down(md) {
    padding-bottom: 7.8rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 736px;
    background-color: $light;
    z-index: -2;
    border-radius: 9001px;
    width: 1635px;
    margin-inline: auto;
    margin-top: -21.7rem;

    @include media-breakpoint-only(md) {
      height: 486px;
      width: 1135px;
    }

    @include media-breakpoint-down(md) {
      height: 990px;
      width: 980px;
      margin-top: -28.7rem;
    }
  }

  h2 {
    text-align: center;
    max-width: 514px;
    margin: 2rem auto 6.9rem;
    padding-inline: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.9rem;
    }
  }
}

.book-testimonials {
  background: $white;
  box-shadow: 0 0 30px rgba($black, 0.1);
  border-radius: 0.8rem;
  max-width: 1147px;
  margin-inline: auto;
  text-align: center;

  @include media-breakpoint-down(md) {
    background: none;
    margin-inline: -2.7rem;
    border-radius: 0;
    box-shadow: none;
  }

  [data-content-type='text'] {
    font-size: 1.6rem;
    max-width: 762px;
    margin-inline: auto;
    padding: 4.5rem 2rem;

    @include media-breakpoint-down(lg) {
      padding-inline: 3rem;
    }

    @include media-breakpoint-down(md) {
      transition: $transition-fade;
    }
  }

  blockquote {
    margin-bottom: 3.7rem;
    font-size: 2.5rem;
    font-style: normal;
    line-height: 1.52;

    @include media-breakpoint-down(lg) {
      margin-bottom: 1.4rem;
      font-size: 1.6rem;
      line-height: 1.75;
    }

    + p {
      margin-bottom: 0;
    }
  }

  .pagebuilder-column {
    min-height: 363px;
    display: flex !important;
    justify-content: center !important;

    @include media-breakpoint-down(md) {
      min-height: 297px;
      background: $white;
      box-shadow: 0 0 30px rgba($black, 0.1);
      border-radius: 0.4rem;
    }
  }

  .slick-slider {

    .slick-list {

      @include media-breakpoint-down(md) {
        padding: 3rem 1.9rem;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;

      @include media-breakpoint-down(md) {
        margin: 0 7.5px;

        > div {
          display: flex;
          height: 100%;
        }

        &:not(.slick-active),
        &.slick-active:nth-last-child(2 of .slick-active) {

          [data-content-type='text'] {
            opacity: 0.2;
          }
        }
      }
    }

    .slick-dots {
      top: 100%;
      bottom: auto;
      margin-top: 2.4rem;

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }

    .slick-prev {
      right: 100%;
      left: auto;
      margin-right: 3.5rem;
      top: 50%;

      @include media-breakpoint-down(md) {
        left: 0;
        right: auto;
        margin-right: 0;

        &::before {
          transform: translate(-50%, -50%) rotate(180deg) !important;
        }
      }
    }

    .slick-next {
      left: 100%;
      right: auto;
      margin-left: 3.5rem;
      top: 50%;

      @include media-breakpoint-down(md) {
        right: 0;
        left: auto;
        margin-left: 0;
      }
    }

    .slick-prev,
    .slick-next {
      transform: translateY(-50%);

      @include media-breakpoint-down(md) {
        width: 16vw;
        background: none !important;
        top: 0;
        transform: none;
        height: 100%;
        border: none;

        &::before {
          width: 44px;
          height: 44px;
          background-color: $black;
          border-radius: 50%;
          border: 2px solid $white;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }
    }
  }
}

.book-faq {

  @include media-breakpoint-down(md) {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  h2 {
    margin-top: 3.5rem;
    margin-bottom: 8.2rem;
    text-align: center;

    @include media-breakpoint-only(xs) {
      max-width: 294px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6.6rem;
      font-size: 2.5rem;
    }
  }

  h3:hover {
    cursor: pointer;
  }

  [data-content-type='faq'] {
    max-width: 1147px;
    margin-left: auto;
    margin-right: auto;

    [data-content-type='faq-item'] {
      margin-bottom: 0 !important;

      [data-element='question'] {
        padding-top: 3.7rem !important;
        padding-bottom: 3.7rem !important;
        padding-right: 5.5rem !important;

        @include media-breakpoint-only(xs) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.book-cta-large {
  margin-top: 5.8rem;
  margin-bottom: 8rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-top: 4.5rem;
    margin-bottom: 7.1rem;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  .btn-outline-secondary {
    @extend %book-style;

    display: flex !important;
    margin: 1.3rem auto 0;
    padding: 0;

    a {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      color: inherit;
      text-decoration: none;
      font-weight: 700;
    }
  }

  .pagebuilder-button-primary,
  .btn-outline-secondary {
    width: 39rem;
    height: 60px;
    font-size: 1.6rem;
  }
}

.book-other-services.book-other-services.book-other-services {
  padding-bottom: 12.3rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 16.3rem;
  }

  .row-full-width-inner {
    max-width: 718px;
  }

  h2 {

    @include media-breakpoint-down(md) {
      margin-bottom: 5.7rem;
    }
  }

  h3 {
    max-width: 216px;

    &::before {
      display: none;
    }
  }

  .pagebuilder-column-line {

    @include media-breakpoint-down(md) {
      gap: 4.4rem;
    }
  }

  .pagebuilder-column {
    padding-bottom: 0;
  }

  .btn-outline-secondary {
    @extend %book-style;

    display: flex !important;
    margin: 6.3rem auto 0;
    width: 180px;
    height: 44px;

    @include media-breakpoint-down(md) {
      margin: 4.4rem auto 0;
    }

    a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      font-weight: 700;
      font-size: 1.4rem;
    }
  }
}
