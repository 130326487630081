.modals-overlay {
  background-color: rgba($black, 0.49);
}

.btn-set-vehicle,
.btn-clear-vehicle {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.modal-slide.set-vehicle-modal {
  left: 0;
  max-width: 577px;
  width: calc(100% - 60px);
  overflow: visible;

  @include media-breakpoint-up(md) {
    margin-left: auto;
  }

  .modal-header {
    padding: 0;
  }

  .modal-inner-wrap {

    @include media-breakpoint-down(md) {
      transform: translateX(-100%);
    }
  }

  .action-close {
    width: 4.8rem;
    height: 4.8rem;
    padding: 0;
    background-image: url(../images/icon-close.svg);
    background-position: center center;
    background-repeat: no-repeat;
    top: 1.9rem;
    right: -5.4rem;
    border: none;
    background-color: transparent;
    outline: 0;

    @include media-breakpoint-up(md) {
      right: 1rem;
      top: 1rem;
    }
  }

  .modal-content {
    padding: 0;
  }

  .content-top {
    background: $primary;
    color: $white;
    text-align: center;
    font-size: 1.6rem;
    padding: 3.3rem;

    >p {
      color: $white;
      font-size: $h4-font-size;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: $h3-font-size;
      }
    }

    >div {
      max-width: 311px;
      margin: auto;
    }
  }

  .setvehicle-result-booking {
    display: none;
  }

  .your-vehicle-container {
    background: rgba($gray-500, 0.102);
    padding: 1.8rem 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 1;
    font-size: 1.4rem;

    .btn-secondary {
      padding: 0.87rem;
      min-width: 130px;
    }

    .btn-clear-vehicle {
      color: $primary;
      border-color: $primary;
      font-size: 1.2rem;
      padding: 0.9rem 2.2rem;
      background: $white;
      margin-top: 1.3rem;
    }

    @include media-breakpoint-up(md) {
      padding: 3rem 6.3rem;
      font-size: 1.6rem;

      .actions {
        flex: 0 0 130px;
        margin-bottom: auto;
      }
    }

    @include media-breakpoint-down(md) {

      .vehicle-holder {
        width: 100%;
      }

      .actions {
        margin-top: 1.5rem;
      }

      .btn-clear-vehicle {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }

    .field-title {
      font-weight: 700;
      width: 100%;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    .rego-holder {
      margin-bottom: 1rem;
    }

    .vehicle-detail {
      margin-bottom: 1rem;
    }

    .vehicle-series {
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-down(md) {

    &._show .modal-inner-wrap {
      transform: translateX(0);
    }
  }
}

.set-vehicle-widget {
  max-width: 577px;
  margin: auto;

  .setvehicle-form-error {
    padding: 1rem 2.2rem;
    margin: 3rem 2.2rem 0;

    @include media-breakpoint-up(md) {
      margin: 3rem 6.3rem 0;
    }
  }
}

.set-vehicle-form-container {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 2.2rem;
  gap: 6px;
  row-gap: 1.2rem;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 3rem 6.3rem;
    gap: 12px;
  }

  .tab.title {
    flex-basis: calc(50% - 0.6rem);
    flex-grow: 1;
    font-weight: 700;
    font-size: 1.6rem;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem 0.4rem 0 0;
    border: 1px solid $input-border-color;
    cursor: pointer;

    .switch {
      text-decoration: none;
      color: $body-color;
    }

    &.active {
      background-color: $black;

      .switch {
        color: $white;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      min-height: 65px;
    }
  }

  .tab.content {
    order: 2;
    width: 100%;
    display: none;
    padding-top: 2rem;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background: $gray-400;
      position: absolute;
      left: 0;
      top: calc(59px + 3rem);

      @include media-breakpoint-up(md) {
        top: calc(64px + 3rem);
      }
    }

    &[aria-hidden='false'] {
      display: block;
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .label {
      display: block;
      font-size: 1.6rem;
      line-height: 1;
      margin-bottom: 1rem;
    }
  }

  .actions-toolbar {
    margin-top: 0.5rem;
    text-align: right;
  }
}
