.find-products-wrapper {

  @include media-breakpoint-down(md) {
    padding-bottom: 2.9rem;
  }
}

.find-products {
  background-image: url(../images/homepage/sv-home-category_2x_4.png);
  position: relative;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-position: 50% 50% !important;
  background-size: cover;
  padding-bottom: 18%;
  display: flex;
  align-items: center;
  justify-content: center !important;
  color: $body-color;
  z-index: 1;

  @include media-breakpoint-down(xl) {
    padding-bottom: 32%;
  }

  @include media-breakpoint-down(md) {
    background-image: url(../images/homepage/set-my-vehicle-mobile_2x_1.png);
    padding-bottom: 64.7%;
    margin-top: 14.5rem;
  }

  .find-products-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-down(md) {
      top: -21%;
    }

    .btn-set-vehicle {
      margin: 2rem auto 0;
    }
  }

  .find-products-heading {
    margin-bottom: 1.3rem;
    font-weight: 700;
  }

  p {
    margin: 0;

    &:last-child {
      margin-bottom: 2rem !important;
    }
  }

  h2,
  p {
    font-size: $h2-font-size;
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: $h3-font-size;
      list-style: 3.8rem;
    }
  }

  [data-content-type='html'],
  [data-content-type='buttons'],
  [data-content-type='text'] {
    text-align: center;
    z-index: 2;
  }

  [data-content-type='image'] {
    width: 100%;
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(md) {
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0;
    }

    img {

      @include media-breakpoint-up(md) {
        object-fit: cover;
        height: 260px !important;
        width: 100%;
      }
    }
  }

  .pagebuilder-button {

    &-primary,
    &-secondary {

      @include media-breakpoint-down(md) {
        display: block;
        padding: 1.3rem 4rem;
      }
    }

    &-secondary {

      @include media-breakpoint-down(md) {
        margin-bottom: -1rem;
      }
    }
  }
}
