.navigation,
.header.content {
  max-width: 1522px;
}

.ammenu-header-container .header.content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1.2rem 1.7rem;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    align-items: flex-end;
    padding: 0.6rem 2rem 0.6rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 2.5rem;
  }

  @include media-breakpoint-only(xl) {
    padding-left: 3.4rem;
  }

  &::before,
  &::after {
    display: none;
  }

  .minicart-wrapper {
    display: none;
  }

  .logo {
    order: 1;
    float: none;

    @include media-breakpoint-down(md) {
      margin: 0 auto !important;
    }

    img {
      height: 45px;

      @include media-breakpoint-up(lg) {
        width: 230px;
        height: auto;
      }

      @media (max-width: 1024px) and (min-width: 768px) {
        width: auto;
        height: 60px;
      }
    }
  }

  .header-links {
    order: 3;
    margin-left: 1rem;
  }
}

.header-links {

  .header-links-desktop {
    margin-bottom: 2.3rem;

    @include media-breakpoint-down(md) {
      display: none;
    }

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin: 0 0 0 1rem;
        padding: 0 0 0 1rem;
        line-height: 1.2rem;
        border-left: solid 1px $dark-text;
        font-size: 1.2rem;

        @include media-breakpoint-up(xl) {
          margin: 0 0 0 1.3rem;
          padding: 0 0 0 1.3rem;
        }

        &:first-child {
          border-left: none;
          margin-left: 0;
          padding-left: 0;
        }

        a {
          text-decoration: none;
          color: $dark-text;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .set-vehicle-holder {
    text-align: center;
    max-width: 224px;
    margin: auto;

    .btn-set-vehicle {
      color: $white;
      padding: 1.8rem 2rem;
      color: #fff;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 100%;

      svg {
        margin-right: 0.5rem;
        margin-bottom: 0.4rem;
        height: 1.6rem;
      }

      .myvehicle {
        max-width: 113px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 413px) {
          max-width: 80px;
        }
      }

      .white-check {
        width: 2.4rem;
        height: 2.4rem;
        background-image: url('../images/icon-check-white.svg');
        background-size: cover;
        display: block;
        margin: -0.3rem 0.5rem -0.3rem 0;
        flex-shrink: 0;
      }

      &:hover {
        background-color: $black;
        border-color: $black;
      }

      @include media-breakpoint-down(md) {
        padding: 1.2rem 2rem 1.2rem 2rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 600;
        white-space: nowrap;
        height: 44px;

        svg {
          margin-bottom: 0;
        }
      }
    }
  }
}

.header-cta-container {
  background: $white;

  @include media-breakpoint-up(md) {
    border-bottom: solid 1px $border-color;
  }

  [data-content-type='row'] {

    @include media-breakpoint-up(md) {
      max-width: 1570px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    [data-content-type='column'] {
      border-left: solid 1px $border-color;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      position: relative;

      @include media-breakpoint-down(md) {
        flex-basis: auto;
        background-color: $black;
        border-left: solid 1px $border-color;
        padding-top: 0.7rem;
        padding-bottom: 0;
        width: 50% !important;

        &:first-child {
          border-left: none;
        }

        .cta {
          display: inline-block;
          width: 34px;
          height: 34px;
          background-repeat: no-repeat;
          margin-right: 1.5rem;
        }

        img {
          margin-right: 1.5rem;
        }
      }

      &:first-child {
        border-left: none;
      }

      @include media-breakpoint-up(md) {

        &::after {
          content: ' ';
          display: inline-block;
          width: 24px;
          height: 24px;
          z-index: 1;
          background: url('../images/icon-right.svg') center center no-repeat;
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translateY(-50%);
          transition: right 0.3s;
          pointer-events: none;
        }

        &:hover {
          background-color: $light;

          &::after {
            right: 15px;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {

      .contact-cta {
        display: none !important;
      }
    }
  }

  [data-content-type='text'] {
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      line-height: 0;
    }

    a {
      color: $dark-text;
      font-weight: 700;
      text-decoration: none;
      display: inline-flex;
      align-items: center;

      .cta-find-store {
        background-image: url(../images/icon-header-location.svg);
      }

      .cta-book-service {
        background-image: url(../images/icon-header-service.svg);
      }

      .cta-contact-us {
        background-image: url(../images/icon-header-contact.svg);
      }

      @include media-breakpoint-up(md) {

        img {
          transform: translateX(-50%) translateX(-17px);
          z-index: 2;
        }

        .cta {
          display: inline-block;
          width: 34px;
          height: 34px;
          background-repeat: no-repeat;
          margin-right: 24px;
        }

        &::after {
          content: ' ';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }

      @include media-breakpoint-down(md) {
        color: $white;
        font-size: 1.2rem;
      }
    }
  }
}

.header-links-mobile {
  background-color: $light;
  padding: 4rem 4rem;

  p {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 3.5rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: 2.5rem;
      padding-right: 0.5rem;
      flex-basis: 50%;

      a {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $body-color;
        font-size: 1.4rem;

        &::before {
          content: ' ';
          display: inline-block;
          flex-shrink: 0;
          width: 34px;
          height: 34px;
          border-radius: 50rem;
          background-color: $black;
          z-index: 1;
          margin-right: 1.3rem;
        }

        &:hover {
          text-decoration: underline;
        }

        img {
          position: absolute;
          left: 17px;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.sections.nav-sections {
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    background-color: $black;
    color: $white;
  }

  @include media-breakpoint-down(md) {
    background-color: $white;
  }

  .navigation {

    @include media-breakpoint-down(md) {
      background-color: $white;
    }

    @include media-breakpoint-up(md) {
      background-color: $black;
      color: $white;
    }
  }

  .nav-sections-item-title {
    display: none;
  }

  .nav-sections-item-content {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav-toggle .mobile-menu-closer-container {
  display: none;
}

.nav-open .nav-toggle {

  &::after {
    background: rgba($black, 0.9);
  }

  .mobile-menu-closer-container {

    @include media-breakpoint-down(md) {
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1000;
    }

    .mobile-menu-closer {
      padding: 0;

      img {
        width: 65px !important;
        height: 65px !important;
      }
    }
  }
}

.page-header {
  background: $white;
  z-index: 120;

  @include media-breakpoint-down(md) {
    border: none;
    margin-bottom: 0;
  }
}

.ammenu-button.-hamburger.-trigger,
.nav-toggle {
  position: relative;
  left: auto;
  top: auto;
  border-top: none;
  border-bottom: none;
  width: 45px;
  margin-right: 1rem;

  @include media-breakpoint-down(md) {
    margin: 0 1rem 0 0;
  }

  &::before {
    border: none;
    width: 45px;
    height: 45px;
    content: ' ';
    background-image: url(../images/icon-hamburger.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 69px;
  }
}

.ammenu-header-container {

  @include media-breakpoint-down(md) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 270;
  }
}
