.cms-page-view {

  .breadcrumb {
    margin-bottom: 0.8rem;
  }

  .page-title-wrapper .page-title {
    font-size: $h2-font-size;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
    }
  }

  [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
    max-width: 100%;
  }
}
