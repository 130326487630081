
.message.message {
  padding: 1.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1.4rem;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }

  @include media-breakpoint-down(md) {
    line-height: 2;
    padding: 1.5rem;
    border-radius: 0;
  }

  >*:first-child::before {
    display: none;
  }

  &::before {
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    margin-right: 1rem;
    background-size: cover;
    flex-shrink: 0;
  }

  a {
    display: inline-block;
  }

  strong,
  a {

    @include media-breakpoint-up(xs) {
      padding-right: 2.8rem;
    }
  }

  @include media-breakpoint-only(xs) {

    .parts-for-container {
      display: block;
      max-width: calc(100% - 45px);
    }

    .parts-for {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;

      strong {
        margin-right: 0;
      }
    }
  }

  &.success {
    background-color: $message-success-background;
    color: $message-success-color;

    a {
      color: $message-success-color;

      &:not(:has(+ a)) {
        padding-right: 0;
      }
    }

    &::before {
      background-image: $message-success-image;
    }
  }

  &.info {
    background-color: $message-info-background;
    color: $message-info-color;

    a {
      color: $message-info-color;
    }

    &::before {
      background-image: $message-info-image;
    }
  }

  &.error {
    background-color: $message-error-background;
    color: $message-error-color;

    a {
      color: $message-error-color;
    }

    &::before {
      background-image: $message-error-image;
    }
  }
}
