.breadcrumbs {
  max-width: calc(1472px + (#{$grid-gutter-width} * 2));
  width: 100%;
  padding: 1rem 2rem;

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  a,
  strong {
    color: $body-color;
    font-size: 1.2rem;
  }

  a {
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 700;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  .item:not(:last-child)::after {
    content: '/';
    font-family: $font-family-base;
    font-size: 1.8rem;
    font-weight: 700;
    padding-left: 1rem;
    padding-right: 0.5rem;
    vertical-align: middle;
  }
}
