.booking-form-container {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  background-image: linear-gradient(to bottom, $gray-75, $gray-75 80%, $white);
  position: relative;
  margin-top: 6rem;

  &.title-counter {
    counter-reset: field-legend;
  }

  &::before {
    content: '';
    width: 100%;
    height: 736px;
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    width: 100%;
    height: 736px;
    border-radius: 100% 100% 0 0;
    background-color: $gray-75;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-down(xl) {

    &::before,
    &::after {
      height: 480px;
    }
  }

  @include media-breakpoint-down(lg) {

    &::before,
    &::after {
      height: 300px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 4rem;

    &::before,
    &::after {
      height: 120px;
      padding-top: 6rem;
    }
  }

  .container,
  .row {
    --gutter-x: 3rem;
  }

  .section {
    width: 926px;
    padding: 0 2.8rem;
    max-width: 100%;
    margin: auto;
    z-index: 2;

    &.curve-background {
      padding-top: 6rem;

      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }

    &.bundle-options {
      counter-reset: config-counter;

      .label.config-counter::after {
        counter-increment: config-counter;
        content: counter(config-counter);
      }

      .divider:first-of-type {
        display: none;
      }

      .btn-link {
        margin-top: 1.5rem;
        line-height: 1;
        cursor: pointer;
      }

      .row {
        row-gap: 2rem;
      }
    }
  }

  .field {

    &.legend {
      font-weight: 700;
      font-size: $h4-font-size;

      @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
      }

      &.title-counter::before {
        counter-increment: field-legend;
        content: counter(field-legend) '. ';
      }
    }

    &.title {
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 2rem;
      line-height: 1;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }

    &.note {
      margin-top: 2rem;
      font-size: 1.6rem;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
    }

    &.choice {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .label {
        margin: 0;
      }
    }

    &.telephone {

      > .control {
        position: relative;

        input[type='tel'] {
          padding-left: 8rem;
        }

        &::before {
          content: '+61';
          color: $gray-500;
          position: absolute;
          top: 1.5rem;
          left: 0;
          border-right: 2px solid rgba($gray-500, 0.7);
          height: 30px;
          line-height: 30px;
          padding: 0 2rem;
        }
      }
    }
  }

  fieldset {
    background: $white;
    box-shadow: 0 0 30px rgba($black, 0.102);
    padding: 3.2rem;
    margin-top: 3rem;
    row-gap: 3rem;
    border-radius: 4px;
    line-height: 1;

    &.row {
      padding: 3.2rem 1.7rem;
    }

    @include media-breakpoint-up(md) {
      padding: 5rem;
      margin-top: 3.6rem;
      row-gap: 4rem;

      &.row {
        padding: 5rem 3.5rem;
      }
    }

    .label {
      margin-bottom: 1rem;
    }

    .field.required {

      .label::after {
        content: ' (Required)';
      }
    }
  }

  .fields-group {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;

    .field.choice {
      min-height: $input-height;
      min-width: 180px;
      position: relative;

      .label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 1rem 2rem 1rem 6.4rem;
        outline: 1px solid $input-border-color;
        border-radius: 4px;
        cursor: pointer;
      }

      input[type='radio'] {
        position: absolute;
        left: 2.5rem;
        top: 50%;
        transform: translateY(-50%);

        &:checked + .label {
          outline: 2px solid $input-focus-border-color;
          background: $gray-50;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .section.help-info {

    .field.assist {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 2.5rem;
      }
    }

    .field.addition-info {
      display: none;
    }
  }

  .section.details {

    .container {
      max-width: 100%;
    }
  }

  .section.set-vehicle {

    >fieldset {
      padding: 0;
    }

    .field.vehicle {

      .mage-error {
        padding: 3rem 5rem 0;
      }
    }

    .set-vehicle-widget {
      max-width: 100%;
    }

    .setvehicle-result-booking {

      >.message {
        margin: 0 2.6rem 5rem;

        @include media-breakpoint-up(md) {
          margin: 0 5rem 5rem;
        }
      }

      .setvehicle-form-toggle {
        cursor: pointer;
        color: $body-color;
        margin-left: 1rem;
      }
    }

    .set-vehicle-form-container {
      margin: 2rem 0;
      padding: 3rem 2.6rem;

      fieldset {
        box-shadow: none;
        padding: 0;
        margin: 0;
      }

      @include media-breakpoint-up(md) {
        padding: 3rem 5rem;
      }

      &.d-none + .setvehicle-result-booking > .message {
        margin-top: 5rem;
      }
    }
  }

  .section.select-store {

    .amlocator-nearst-stores {

      .field.find {

        .control {
          position: relative;
          flex-grow: 1;
          width: 100%;

          @include media-breakpoint-down(md) {
            display: flex;
            flex-wrap: wrap;
            row-gap: 2rem;
          }
        }

        .change-store {
          flex-shrink: 0;
          color: $body-color;
          padding: 0;
          width: auto;
          font-size: 1.6rem;

          @include media-breakpoint-up(md) {
            margin-left: 2.4rem;
          }

          @include media-breakpoint-down(md) {
            margin: 1.4rem 2.5rem 0 auto;
          }
        }
      }

      div[id='booking_store-error'] {
        display: none !important;
      }

      .result-list.loaded + .booking-store div[id='booking_store-error'] {
        display: block !important;
      }

      [data-amlocator-js='find-button'] {
        top: 0.8rem;
        right: 0.5rem;
        padding: 1.3rem;
        min-width: 153px;
        margin-left: auto;

        @include media-breakpoint-up(md) {
          position: absolute;
        }
      }
    }

    .field-title {
      font-size: 1.4rem;
      margin: 2.6rem 0 2rem;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin: 6.2rem 0 2rem;
      }
    }

    .fields-group {

      .field.choice {
        width: 100%;
      }
    }
  }

  .btn-primary {
    height: $input-height;
    max-width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    padding: 1.3rem 5.4rem;
    border: none;
  }

  .security-note {
    font-size: 1.4rem;
    margin: 3rem auto 1rem;
    display: flex;
    gap: 0.7rem;
    width: 100%;
    max-width: 450px;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
      padding-bottom: 1rem;
    }

    img {
      margin-top: -0.6rem;
    }
  }

  div[id='rego_make_model-error'],
  div[id='booking_store-error'],
  div[id='prefer_time_input-error'] {

    &.mage-error {
      margin-top: 1.5rem;
      font-size: 1.6rem;
    }
  }
}

.contactus-enquiry {

  .section.help-info {

    .field.addition-info {
      display: block;
    }
  }
}
