.page-layout-cms-landing {

  .columns .column.main {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;

    @include media-breakpoint-down(md) {
      margin-top: -9rem;
    }
  }

  .breadcrumbs {

    @include media-breakpoint-up(md) {
      position: relative;
      z-index: 1;
      margin-bottom: -39px;

      a,
      strong {
        color: $white;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .landing-banner {
    position: relative;
    z-index: 0;
    max-width: 1570px;
    margin-inline: auto;
    height: 300px;
    background-color: $dark;
    color: $white;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 2rem;
    background-position: center !important;

    @include media-breakpoint-up(md) {
      margin-bottom: 7rem;
      font-size: 6rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(97deg, rgba($black, 0.7) 0%, rgba($black, 0) 100%) 0% 0% no-repeat padding-box;

      @include media-breakpoint-down(md) {
        background: transparent linear-gradient(114deg, rgba($black, 0.7) 0%, rgba($black, 0) 100%) 0% 0% no-repeat padding-box;
      }
    }

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      margin: 0;
      transform: translate(-50%, -50%);
    }
  }

  .main {

    [data-content-type='row'][data-appearance='contained'] {
      max-width: 1296px;
      padding-inline: 3.1rem;

      @include media-breakpoint-up(md) {
        padding-inline: 2rem;
      }
    }

    .landing-centred-mobile {

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .landing-centred {
      text-align: center;

      h1 {
        max-width: 610px;
        margin-left: auto;
        margin-right: auto;
      }

      &.landing-content {

        @media (min-width: 1570px) {
          margin-inline: -5.7rem;

          .sidebar + .pagebuilder-column:not(:last-child):not(.sidebar) {
            max-width: 770px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      &.landing-content-lg h2 {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .landing-content {

      .pagebuilder-column-group {

        @include media-breakpoint-up(md) {
          margin-bottom: 4.5rem;
        }

        @include media-breakpoint-down(md) {
          padding-bottom: 1rem;

          &.mobile-oval {
            position: relative;
            z-index: 0;
            margin-inline: -3rem;
            padding-inline: 3rem;
            overflow: hidden;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              width: 1635px;
              margin: 0 auto;
              z-index: -1;
              background-color: $gray-50;
              top: calc(100% - 280px);
              height: 736px;
              border-radius: 50% 50% 0 0;

              @include media-breakpoint-only(xs) {
                top: calc(100% - 56vw);
              }
            }
          }
        }
      }

      .pagebuilder-column {
        flex-grow: 1;
        width: auto !important;
        line-height: 1.75;

        &.sidebar {

          @include media-breakpoint-only(md) {
            max-width: 200px;
            width: 200px !important;
          }

          @include media-breakpoint-up(lg) {
            max-width: 229px;
            width: 229px !important;
          }

          &:last-child {

            @include media-breakpoint-down(lg) {
              display: none !important;
            }

            @include media-breakpoint-up(lg) {
              margin-left: 7.5rem !important;
            }
          }
        }

        &:first-child {
          width: 100% !important;
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 7.5rem;

          @include media-breakpoint-only(md) {
            width: 200px !important;
          }

          @include media-breakpoint-up(lg) {
            width: 229px !important;
          }

          p {
            margin-bottom: 1.6rem;
            padding: 1.1rem 1rem;
            border-radius: 4px;
            background-color: rgba($gray-500, 0.1);
            font-weight: 700;
          }

          ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            font-size: 1.4rem;
          }

          a {
            color: inherit;

            &:hover,
            &:focus,
            &.active {
              color: $red;
            }

            &.active {
              font-weight: 700;
            }

            @include media-breakpoint-up(md) {
              padding-inline: 1rem;
            }
          }
        }

        &:last-child:not(:nth-child(2)) {

          @include media-breakpoint-down(md) {
            margin: 1.7rem 0 2rem;

            figure {
              width: 100%;
              margin-bottom: 2rem;
            }
          }

          @include media-breakpoint-up(md) {
            align-self: flex-start !important;
            flex-basis: 200px;
            flex-shrink: 0;
            margin-left: 4rem;
          }

          @include media-breakpoint-only(lg) {
            flex-basis: 280px;
          }

          @include media-breakpoint-only(xl) {
            flex-basis: 383px;
            margin-left: 6.2rem;
          }
        }

        &:nth-child(2):not(:last-child) {

          @include media-breakpoint-down(md) {
            padding-inline: 0.8rem;
          }
        }
      }
    }

    .landing-content-lg {
      line-height: 1.75;

      @include media-breakpoint-up(md) {
        margin-bottom: 8rem;
      }

      .pagebuilder-column-group {

        + .pagebuilder-column-group {
          margin-top: 4rem;

          @include media-breakpoint-up(md) {
            margin-top: 9rem;
          }
        }

        &:nth-child(even) .pagebuilder-column:last-child {

          @include media-breakpoint-up(md) {
            order: -1;
          }
        }

        .pagebuilder-column:first-child {

          @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
          }
        }
      }

      h2 {
        max-width: 524px;
      }

      .pagebuilder-column {
        align-items: center;
        justify-content: center !important;

        &:first-child {
          flex-grow: 1;
          padding-inline: 3rem;

          @include media-breakpoint-down(md) {
            padding-inline: 0;
          }

          @include media-breakpoint-only(lg) {
            padding-inline: 5rem;
          }

          @include media-breakpoint-only(xl) {
            max-width: 784px;
            margin-inline: auto;
            padding-inline: 8rem;
          }
        }

        &:last-child {

          @include media-breakpoint-up(md) {
            max-width: 684px;
          }
        }
      }
    }

    .landing-content-xl {
      position: relative;
      z-index: 0;
      max-width: 1570px;
      margin: 5rem auto 5rem;
      background-color: $black;
      background-position: center !important;
      color: $white;
      height: 414px;
      align-items: center;
      justify-content: center !important;
      padding-inline: 2.7rem;
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        margin: 5rem auto 8rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: transparent linear-gradient(107deg, rgba($black, 0.7) 0%, rgba($black, 0) 100%) 0% 0% no-repeat padding-box;

        @include media-breakpoint-down(md) {
          opacity: 0.5;
          background: $black;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
        height: 760px;
      }

      @include media-breakpoint-up(lg) {
        padding-inline: 15rem;
      }

      [data-content-type='text'] {
        max-width: 644px;
      }

      h2 {
        font-size: 4rem;
        line-height: 1.25;
        color: inherit;

        @include media-breakpoint-up(md) {
          margin-bottom: 1.2rem;
          line-height: 1.2;
          font-size: 6rem;
        }
      }
    }

    .landing-cta-lg h2 {
      max-width: 314px;
      margin: 1rem auto 0;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        max-width: 564px;
        margin-top: 1.6rem;
        line-height: 1.3;
        font-size: 4rem;
      }
    }

    [data-appearance='full-bleed'] {
      max-width: 1490px;
      margin-left: auto;
      margin-right: auto;
      padding-inline: 3.1rem;

      @include media-breakpoint-up(md) {
        padding-inline: 2rem;
      }
    }

    h1 {
      margin-bottom: 2.5rem;
      font-weight: 400;
    }

    .sidebar + .pagebuilder-column [data-content-type='text']:not(:first-child) h1 {

      @include media-breakpoint-down(md) {
        margin-top: 2rem;
        font-size: 2.5rem;
      }
    }

    h2 {
      margin-bottom: 2.1rem;
      font-size: 3.2rem;

      @include media-breakpoint-down(md) {
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
      }
    }

    p {

      @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
      }
    }

    .promo-banner-bottom-container .pagebuilder-banner-wrapper p {

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .lead {
      line-height: 1.4;

      @include media-breakpoint-up(md) {

        p {
          margin-bottom: 5rem;
        }
      }
    }

    .landing-statement {
      margin-bottom: 6rem;
    }

    .sidebar {
      text-align: left;

      @include media-breakpoint-down(md) {
        margin-bottom: 1.8rem;

        [data-content-type='text']:first-child {
          scroll-margin-top: 8.8rem;

          p {
            position: relative;
            z-index: 0;
            background-color: $white;
            border: 1px solid rgba($gray-500, 0.7);
            border-radius: 0.4rem;
            height: 60px;
            line-height: 2.3;
            padding-left: 2.5rem;
            padding-right: 5rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 25px;
              width: 24px;
              height: 24px;
              z-index: 0;
              background-repeat: no-repeat;
              background-size: 24px 24px;
              background-position: center;
              background-image: url('../images/icon-down.svg');
              transform: translateY(-50%);
            }
          }
        }

        &.active [data-content-type='text']:first-child p::before {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .pagebuilder-button-primary {
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;

      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

      font-size: 1.8rem;
    }

    > div:nth-last-of-type(2) {
      padding-bottom: 8rem;
    }
  }

  .pagebuilder-video-inner {
    margin: 2.4rem 0 4rem;

    @include media-breakpoint-up(md) {
      margin: 4.8rem 0 3.8rem;
    }
  }

  [data-content-type='video']:only-child .pagebuilder-video-inner {

    @include media-breakpoint-up(md) {
      margin-bottom: 5.8rem;
    }
  }

  .landing-bottom {
    position: relative;
    z-index: 0;
    margin-top: 7.5rem;
    padding-top: 16rem;
    text-align: center;
    overflow: hidden;

    ~ div {
      padding-bottom: 0 !important;
      background-color: $gray-50;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
      padding-top: 2.5rem;
      background-color: $gray-50;
    }

    &::before,
    &::after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1635px;
      margin: 0 auto;
      z-index: -1;
      background-color: $gray-50;

      @include media-breakpoint-only(xl) {
        width: 104.141%;
      }
    }

    &::before {
      content: '';
      top: 0;
      height: 736px;
      border-radius: 50% 50% 0 0;

      @include media-breakpoint-down(md) {
        border-radius: 0;
      }
    }

    &::after {
      content: '';
      top: 736px;
      height: 100%;
    }

    .book-service-title {
      max-width: 624px;
      margin-inline: auto;
      margin-bottom: 5rem;
      padding-inline: 3.2rem;
      line-height: 1.63;
      font-size: 1.8rem;
    }

    .book-service-container {
      background: none;

      @include media-breakpoint-down(md) {
        padding-inline: 3.2rem;

        .pagebuilder-column-line {
          gap: 4.1rem;
        }

        img {
          width: 100%;
        }
      }

      @include media-breakpoint-up(lg) {

        .pagebuilder-column-group {
          max-width: 1476px;
        }

        .pagebuilder-column-line {
          gap: 3.2rem;
        }
      }
    }

    .pillars-container {
      margin-bottom: 5rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 8rem;
      }

      .pagebuilder-video-inner {
        margin: 0;
      }

      h3 {
        padding-top: 3rem;
        padding-bottom: 2rem;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 2.7rem;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 4rem;
    }

    h3 {
      max-width: 180px;
      padding-block: 4.7rem;
    }

    figure {
      margin-bottom: 0;
    }

    .pagebuilder-column {
      gap: 0;
      overflow: hidden;

      &:first-child h3 {
        max-width: 190px;
      }
    }
  }

  &.cms-franchise h1 {
    max-width: 880px;
  }
}
