.unbxd-as-wrapper.unbxd-as-wrapper {
  border: none !important;
  font-size: 1.6rem;
  left: 50% !important;
  transform: translateX(-50%) !important;
  max-width: 1460px !important; /* 973 for 1 */
  width: 100% !important;
  margin: 0;
  height: 650px;
  padding-top: 2.4rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 102px !important;
  z-index: 119 !important;

  ul li:hover {
    background: transparent !important;
  }

  @include media-breakpoint-down(lg) {
    left: 0 !important;
    transform: translateX(0) !important;
    overflow: hidden;
    margin: 0;
    height: auto;
  }

  @include media-breakpoint-down(md) {
    position: fixed !important;
    bottom: auto !important;
    overflow-y: auto;
    top: 113px !important;
    height: calc(100dvh - 113px) !important;
    padding-bottom: 0;
    padding-top: 9.9rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overscroll-behavior: none;
  }

  ul.unbxd-as-sidecontent > li,
  ul.unbxd-as-maincontent > li {
    padding: 0;
    padding-left: 5rem !important;

    @include media-breakpoint-down(lg) {
      padding-left: 3.5rem !important;
    }
  }

  ul.unbxd-as-sidecontent {
    border: none;
    border-top: 1px solid $border-color;
    display: block;
    height: 100%;
    position: relative;
    width: 33.33% !important; /* 485px */

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: auto;
      border: none;
    }

    li.unbxd-as-header.unbxd-as-header {
      background-color: transparent;
      color: $black;
      font-weight: bold;
      padding: 3rem 0;
      margin: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }

    li.unbxd-as-category-list-content-wrapper {
      margin-bottom: 1rem;

      ul.unbxd-as-category-list li {
        margin-bottom: 4rem;
        padding: 0;
        cursor: default;

        a {
          color: $black;
          font-weight: bold;
        }
      }
    }

    li.unbxd-as-sideblock-wrapper {
      margin-right: 3%;
      cursor: default;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  ul.unbxd-as-suggestions-overall {
    width: 66.67% !important; /* 973px */
    border: none;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    position: relative;
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    @include media-breakpoint-down(lg) {
      height: auto;
      padding-bottom: 2.5rem;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      border: none;
      padding-bottom: 0;
    }

    li.unbxd-as-header.unbxd-as-header,
    li.unbxd-as-header.unbxd-as-header:hover {
      background-color: transparent;
      color: $black;
      font-weight: bold;
      padding: 3rem 0;
      margin: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
      }
    }

    li.unbxd-as-popular-product-grid {
      width: calc((100% - 18%) / 2) !important;
      padding: 0;
      margin-left: 3%;
      margin-bottom: 2.3rem;

      @include media-breakpoint-down(lg) {
        width: 100% !important;
        margin-left: 0;
      }

      .unbxd-as-popular-product-info {
        width: 90%;
        padding: 0;

        .unbxd-as-popular-product-image-container.unbxd-as-popular-product-image-container {
          float: left;
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;

          img {
            max-width: 94px;
            width: 94px;
          }

          .unbxd-as-placeholder {
            width: 94px;
            height: 94px;
            background: url('../images/product-image-scaled.png') center center no-repeat;
          }
        }

        .unbxd-as-popular-product-info-right {
          float: left;
          height: 100px;
          width: calc(100% - 125px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 0 0 2rem;

          .unbxd-as-popular-product-name {
            width: auto;
            text-align: left;
            line-height: 1.2;
            height: 2.2rem;
          }

          .unbxd-as-popular-product-price {
            padding: 0;
            line-height: normal;
            text-align: left;
            font-weight: bold;
          }
        }
      }
    }

    li.unbxd-as-footer {
      display: block;
      width: 100%;
      left: 0;
      text-align: center;
      position: absolute;
      bottom: 25px;
      margin-left: 0 !important;
      padding-left: 0 !important;
      cursor: default;

      @include media-breakpoint-down(lg) {
        position: relative;
        bottom: auto;
        float: left;
        margin-bottom: 0;
      }

      #unbxd-as-view-all {
        display: block;
        width: 92%;
        margin: auto;
        padding: 2rem 1.5rem;
        background: black;
        color: white;
        text-decoration: none;
        border-radius: 4px;
        font-size: 1.8rem;
        font-weight: 600;
        position: relative;

        @include media-breakpoint-down(md) {
          width: 100%;
          border-radius: 0;
        }

        span.arrow-right {
          display: inline-block;
          background: black url('../images/icon-arrow-white.svg') center center no-repeat;
          width: 15px;
          height: 15px;
          margin-left: 2rem;
          vertical-align: middle;
        }
      }
    }
  }
}

#unbxd-as-overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
