.page-layout-cms-standard {

  .columns .column.main {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 9.5rem;
    }
  }

  h1 {
    margin-bottom: 5rem;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      margin-bottom: 4.2rem;
    }
  }

  .main {
    line-height: 1.75;

    [data-content-type='row'][data-appearance='contained'] {
      max-width: 1008px;
      padding-inline: 3.1rem;

      @include media-breakpoint-up(md) {
        padding-inline: 2rem;
      }
    }

    [data-appearance='full-bleed'] {
      max-width: 1490px;
      margin-left: auto;
      margin-right: auto;
      padding-inline: 3.1rem;

      @include media-breakpoint-up(md) {
        padding-inline: 2rem;
      }
    }

    h2 {
      margin-bottom: 3rem;
      font-size: 2.5rem;

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    p {
      margin-bottom: 2.8rem;
    }

    .lead {
      line-height: 1.4;
    }

    .pagebuilder-button-primary {
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;

      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);

      font-size: 1.8rem;
    }
  }

  .pagebuilder-video-inner {
    margin: 2.4rem 0 4rem;

    @include media-breakpoint-up(md) {
      margin: 4.8rem 0 3.8rem;
    }
  }

  [data-content-type='video']:only-child .pagebuilder-video-inner {

    @include media-breakpoint-up(md) {
      margin-bottom: 5.8rem;
    }
  }
}
